import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { GenericErrorResponse } from "../../errors";
import type { ServiceDetails } from "./definitions";

export type UseV1ServiceSuspenseParams = {
  slug: string;
  expand?: (
    | "adult_service"
    | "children_service"
    | "extra_services"
    | "min_price"
  )[];
  doctor?: number | string;
  type?: "initial-appointment" | "procedure";
};

export type UseV1ServiceSuspenseResult =
  | {
      ok: true;
      data: ServiceDetails;
    }
  | {
      ok: false;
      error: GenericErrorResponse;
    };

type UseV1ServiceSuspenseOptions =
  UseSuspenseQueryOptions<UseV1ServiceSuspenseResult>;

export const useV1ServiceSuspense = (
  { slug, ...params }: UseV1ServiceSuspenseParams,
  options?: Partial<UseV1ServiceSuspenseOptions>,
) => {
  const url = `/api/v1/services/${slug}/`;
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => ({ ok: true as const, data: r.data }),
          404: async (r) => ({ ok: false as const, error: r.data }),
        },
      );
    },
  });
};

export type UseV1ServiceResult = ServiceDetails;

type UseV1ServiceQueryParams = UseV1ServiceSuspenseParams;
type UseV1ServiceQueryOptions = UseQueryOptions<UseV1ServiceResult, Error>;

export const useV1Service = (
  { slug, ...params }: UseV1ServiceQueryParams,
  options?: Partial<UseV1ServiceQueryOptions>,
) => {
  const url = `/api/v1/services/${slug}/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV1ServiceResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};
