import { useCallback } from "react";

import { useLocale } from "../locale";

export const useFormatNumber = () => {
  const [locale] = useLocale();

  return useCallback(
    (value: number, options?: Intl.NumberFormatOptions) => {
      const nf = new Intl.NumberFormat(locale.toString(), {
        ...options,
        // Older Safari versions (< 14) have a default of 2 for minimumFractionDigits
        // and throw a RangeError if maximumFractionDigits is less than
        // minimumFractionDigits.
        minimumFractionDigits:
          options?.minimumFractionDigits ?? options?.maximumFractionDigits,
      });

      return nf.format(value);
    },
    [locale],
  );
};

const CURRENCY_EXCEPTIONS = {
  KGS: {
    ru: "{price} cом",
  },
  HUF: {
    en: "{price} Ft",
  },
  KZT: {
    ru: "{price} ₸",
    kk: "{price} ₸",
  },
} as {
  [currency: string]: {
    [locale: string]: string;
  };
};

export const useFormatCurrencySign = () => {
  const formatNumber = useFormatNumber();
  const [locale] = useLocale();

  return useCallback(
    (currency: string) => {
      const exception = CURRENCY_EXCEPTIONS[currency]?.[locale.baseName];

      if (exception) {
        return exception.replace("{price}", "").trim();
      }

      return currency;
    },
    [formatNumber],
  );
};

export const useFormatCurrency = () => {
  const formatNumber = useFormatNumber();
  const [locale] = useLocale();

  return useCallback(
    (value: number, currency: string, options?: Intl.NumberFormatOptions) => {
      const exception = CURRENCY_EXCEPTIONS[currency]?.[locale.baseName];

      const formatted = formatNumber(value, {
        ...options,
        style: "currency",
        currency,
        currencyDisplay: "symbol",
      });

      if (exception && formatted.includes(currency)) {
        return exception.replace(
          "{price}",
          formatted.replace(currency, "").trim(),
        );
      }

      return formatted;
    },
    [formatNumber],
  );
};
