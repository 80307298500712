import { useRef } from "react";
import { useButton, useFocusRing } from "react-aria";

import { cssFns } from "../css";
import { usePrincipalColors } from "../theme";
import { FieldDescription } from "./common/description";
import { FieldError } from "./common/error";
import { BaseFieldInput, BaseFieldLayout } from "./common/field";
import { type Field, type FieldState, useBaseFieldState } from "./common/form";
import { FieldLabel } from "./common/label";
import type { CommonFieldProps } from "./common/props";

export type ButtonFieldState<T> = FieldState<T | null>;

export function ButtonField<T>({
  label,
  description,
  placeholder,
  field,
  onPress,
  valueToString,
  disableLabelFocus,
}: {
  field: Field<ButtonFieldState<T>>;
  onPress?: () => void;
  valueToString: (value: T | null) => string;
} & CommonFieldProps) {
  const principalColors = usePrincipalColors();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isFocused, focusProps } = useFocusRing({
    isTextInput: false,
    within: true,
  });

  const { buttonProps } = useButton(
    { onPress, isDisabled: field.disabled },
    buttonRef,
  );

  return (
    <BaseFieldLayout
      label={
        label && (
          <FieldLabel
            disabled={field.disabled}
            required={field.required}
            disableFocus={disableLabelFocus}
          >
            {label}
          </FieldLabel>
        )
      }
      input={
        <BaseFieldInput
          field={field}
          focused={isFocused}
          fieldProps={focusProps}
          input={
            <button
              ref={buttonRef}
              {...buttonProps}
              css={[
                cssFns.margin("0px"),
                cssFns.border({ width: "0px", style: "none" }),
                cssFns.padding("0px", "10px"),
                {
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  outlineStyle: "none",
                  "&:hover": {
                    cursor: "pointer",
                  },
                },
              ]}
            >
              <div
                css={{
                  display: "grid",
                }}
              >
                <span
                  css={[
                    cssFns.typo({
                      level: "body-1",
                      weight: "regular",
                    }),
                    {
                      overflowX: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      color: principalColors.gs2,
                    },
                  ]}
                >
                  {valueToString(field.value) || placeholder}
                </span>
              </div>
            </button>
          }
          disabled={field.disabled}
        />
      }
      description={
        description && (
          <FieldDescription disabled={field.disabled}>
            {description}
          </FieldDescription>
        )
      }
      error={
        field.error?.visible && (
          <FieldError
            fieldRequired={field.required}
            errorMessage={field.error.message}
          />
        )
      }
    />
  );
}

export function useButtonFieldState<T>(defaultState?: ButtonFieldState<T>) {
  return useBaseFieldState<ButtonFieldState<T>>({
    value: null,
    error: undefined,
    ...defaultState,
  });
}
