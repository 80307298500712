import type { SerializedStyles } from "@emotion/react";
import type { ServiceDetails } from "@hermes/api";
import { Message, useFormatNumber } from "@hermes/intl";
import {
  cssFns,
  usePrincipalColors,
  useScreenDetector,
  useSecondaryColors,
} from "@hermes/ui";
import { useState } from "react";

export const DoctorsListServiceDescription = ({
  styles,
  description,
  count,
  rating,
  service,
}: {
  styles?: SerializedStyles;
  service: ServiceDetails;
  count: number;
  rating: number;
  description: string;
}) => {
  const { isMobile } = useScreenDetector();
  const formatNumber = useFormatNumber();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();

  const [descriptionExpanded, setDescriptionExpanded] = useState(isMobile);

  return (
    <div
      css={[
        cssFns.typo({ level: "body-1", weight: "regular" }),
        {
          color: principalColors.gs2,
          display: descriptionExpanded ? "block" : "flex",
        },
        styles,
      ]}
    >
      {description && (
        <div
          css={[
            {
              p: { marginBlockEnd: isMobile ? 10 : 16 },
              b: [cssFns.typo({ level: "body-1", weight: "semibold" }), {}],
              strong: [
                cssFns.typo({ level: "body-1", weight: "semibold" }),
                {},
              ],
              ul: {
                marginBlockEnd: isMobile ? 10 : 16,
                paddingInlineStart: 30,
              },
            },
            descriptionExpanded
              ? {}
              : {
                  width: "680px",
                  height: "20px",
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  "p:first-of-type": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                },
          ]}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}
      {!descriptionExpanded && !isMobile && (
        <span
          css={[
            {
              color: principalColors.ebblue,
              textDecoration: "underline",
              cursor: "pointer",
            },
          ]}
          onClick={() => {
            setDescriptionExpanded(true);
          }}
        >
          <Message
            id="0c55f599-29de-4817-88dd-b9123c0f1598"
            default="Читать далee"
          />
        </span>
      )}

      <div
        css={{
          ...(!descriptionExpanded && { display: "none" }),
        }}
      >
        <div
          css={[
            cssFns.padding(isMobile ? 10 : 16),
            cssFns.border({ radius: isMobile ? 12 : "4px" }),
            {
              backgroundColor: principalColors.gs10,
              marginBlockEnd: isMobile ? 10 : "16px",
              marginBlockStart: 20,
            },
          ]}
        >
          <ul css={{ margin: "0px", paddingInline: 0 }}>
            <p>
              <Message
                id="a24feb9b-d30c-4a35-9b85-5d504db810c7"
                default="Лучшие {service}: средний рейтинг врача - {rating}"
                values={{
                  service: service.name,
                  rating: formatNumber(rating, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }),
                }}
              />
            </p>
            <p>
              <Message
                id="fecaaa1b-b9f1-4a33-85a4-5f63b6c4dc45"
                default="Проверенные отзывы: {count} проверенных отзыва"
                values={{
                  count,
                }}
              />
            </p>
          </ul>
        </div>
        <div
          css={[
            cssFns.padding(isMobile ? 10 : 16),
            cssFns.border({ radius: isMobile ? 12 : 4 }),
            {
              ...(isMobile && {
                marginBlockEnd: 30,
              }),
              backgroundColor: cssFns.setOpacity(secondaryColors.magenta, 0.2),
            },
          ]}
        >
          <Message
            id="e74346d4-7106-45cc-847b-51c63873e78e"
            default="<hl>Note!</hl> The information on this page is provided for your information only. To prescribe treatment, consult a doctor."
            values={{
              hl: (text: string) => (
                <span
                  key={"hl"}
                  css={{ color: secondaryColors.magenta }}
                  children={text}
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};
