import { css, type CSSObject, type SerializedStyles } from "@emotion/react";
import {
  type ReactNode,
  type RefObject,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import {
  type AriaButtonOptions,
  useComboBox,
  useFocusWithin,
} from "react-aria";
import { InView } from "react-intersection-observer";
import {
  type ComboBoxStateOptions,
  Item,
  Section,
  useComboBoxState as useAriaComboBoxState,
} from "react-stately";

import { ButtonV2 } from "../../button";
import { TriggerButton } from "../../button/trigger-button";
import { cssFns } from "../../css";
import {
  DesktopArrowDown,
  DesktopArrowUp,
  MobileMenuSearch,
} from "../../icons";
import { ListBox } from "../../list-box";
import type { ListBoxOptions } from "../../list-box/options";
import {
  type Content,
  type OverlayContentOptions,
  renderContent,
} from "../../overlays/content";
import { Modal, useModalState } from "../../overlays/modal";
import { Popover } from "../../overlays/popover";
import { useScreenDetector } from "../../screen-type";
import { Spinner } from "../../spinner";
import { usePrincipalColors, useSecondaryColors } from "../../theme";
import type { SizeUnit } from "../../utils";
import { FieldDescription } from "../common/description";
import { FieldError } from "../common/error";
import { BaseFieldInput, BaseFieldLayout } from "../common/field";
import { type Field, type FieldState, useBaseFieldState } from "../common/form";
import { FieldLabel } from "../common/label";
import type { CommonFieldProps } from "../common/props";
import { TextField, useTextFieldStyles } from "../inputs/text-field";

export type ComboboxOption<T> = {
  key: T;
  label: string;
};

export type ComboboxState<T> = FieldState<ComboboxOption<T> | null> & {
  inputValue?: string;
};

export type ComboboxGroup<T> = {
  options: ComboboxOption<T>[];
  label: string;
};

type ComboboxItemOption<T> = {
  type: "option";
  key: string;
  label: string;
  option: ComboboxOption<T>;
  item: JSX.Element;
};

type ComboboxItemGroup<T> = {
  type: "group";
  key: undefined;
  label: string;
  option: undefined;
  item: JSX.Element;
  options: ComboboxItemOption<T>[];
};

type ComboboxItem<T> = ComboboxItemGroup<T> | ComboboxItemOption<T>;

export type ComboboxChildren = {
  children?: (
    inputRef: RefObject<HTMLInputElement>,
    inputProps: React.InputHTMLAttributes<HTMLInputElement>,
    buttonRef: RefObject<HTMLButtonElement>,
    buttonProps: AriaButtonOptions<"button">,
  ) => ReactNode;
};

export type ComboboxImperativeRef = {
  open: () => void;
  close: () => void;
  blur: () => void;
};

export function ComboBox<T>({
  label,
  description,
  placeholder,
  disableLabelFocus,
  options,
  icon,
  field,
  mobile,
  desktop,
  renderItem,
  menuTrigger = "input",
  onSelectionChange,
  showArrowDown = true,
  showSearchIcon,
  isLoading,
  notFoundLabel,
  onBottomReached,
  onFocus,
  clearable,
  toKeyString = (value: T) => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    }

    return String(value);
  },
  imperativeRef,
  children,
  externalStyles,
}: {
  field: Field<ComboboxState<T>>;
  onSelectionChange?: (option: ComboboxOption<T> | null) => void;
  options: ComboboxOption<T>[] | ComboboxGroup<T>[];
  mobile?: {
    subtitle?: Content<OverlayContentOptions>;
  };
  desktop?: {
    width?: SizeUnit;
  };
  renderItem?: ListBoxOptions<T>["renderItem"];
  menuTrigger?: "input" | "focus";
  showSearchIcon?: ReactNode;
  showArrowDown?: boolean;
  icon?: ReactNode;
  isLoading?: boolean;
  notFoundLabel?: string;
  onBottomReached?: () => void;
  onFocus?: () => void;
  clearable?: boolean;
  /**
   * Get unique key string from value
   * By default, converts objects by JSON.stringify which can be slow
   *
   * If can, please, provide simple string value thats unique with each value
   */
  toKeyString?: (value: T) => string;
  imperativeRef?: RefObject<ComboboxImperativeRef>;
  externalStyles?: Array<CSSObject | SerializedStyles>;
} & CommonFieldProps &
  ComboboxChildren) {
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const { isMobile } = useScreenDetector();

  const styles = useTextFieldStyles({
    disabled: field.disabled,
    hasIcon: !!icon,
  });

  const fieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const listBoxRef = useRef<HTMLUListElement>(null);

  const { items, flatOptions } = useMemo(() => {
    const items = options.map((option) => {
      function processOption(option: ComboboxOption<T>): ComboboxItemOption<T> {
        const key = toKeyString(option.key);

        return {
          type: "option" as const,
          key,
          label: option.label,
          option,
          item: (
            <Item key={key} textValue={option.label}>
              {option.label}
            </Item>
          ),
        };
      }

      if ("options" in option) {
        const groupOptions = option.options.map((subOption) =>
          processOption(subOption),
        );

        return {
          type: "group",
          key: undefined,
          label: option.label,
          option: undefined,
          item: (
            <Section key={option.label} title={option.label}>
              {groupOptions.map(({ item }) => item)}
            </Section>
          ),
          options: groupOptions,
        } as ComboboxItem<T>;
      }

      return processOption(option);
    });

    const flatOptions = items.flatMap((item) => {
      if (item.type === "group") {
        return [...item.options];
      }
      return [item];
    });

    return {
      items,
      flatOptions,
    };
  }, [options]);

  const ariaProps: ComboBoxStateOptions<{
    key: string;
    label: string;
  }> = {
    label: label ?? placeholder ?? "combobox",
    description,
    errorMessage: field.error?.message,
    selectedKey:
      field.value?.key !== null && field.value?.key !== undefined
        ? toKeyString(field.value.key)
        : null,
    isRequired: field.required,
    isDisabled: field.disabled,
    isInvalid: !!field.error?.visible,
    allowsCustomValue: false,
    placeholder,
    disabledKeys: items
      .filter(
        (item): item is Extract<ComboboxItem<T>, { type: "group" }> =>
          item.type === "group",
      )
      .map((item) => item.label),
    children: items.map(({ item }) => item),
    items: items
      .filter(
        (item): item is Extract<ComboboxItem<T>, { type: "option" }> =>
          item.type === "option",
      )
      .map((item) => ({
        key: item.key,
        label: item.label,
      })),
    inputValue: (field.inputValue || field.value?.label) ?? "",
    allowsEmptyCollection: true,
    menuTrigger,
    onSelectionChange: (key) => {
      if (field.value?.key === key) return;

      const item = flatOptions.find((item) => item.key === key);

      field.onChange({
        ...field,
        ...(item && {
          value: item.option,
          inputValue: "",
        }),
      });
      onSelectionChange?.(item?.option ?? null);
    },
    onInputChange(inputValue: string) {
      if (inputValue !== "" && field.inputValue === inputValue) return;

      field.onChange({
        ...field,
        ...(inputValue === "" && {
          value: null,
        }),
        inputValue,
      });
    },
  };

  const ariaState = useAriaComboBoxState(ariaProps);

  const {
    labelProps,
    descriptionProps,
    errorMessageProps,
    inputProps,
    buttonProps: baseButtonProps,
    listBoxProps,
  } = useComboBox(
    {
      ...ariaProps,
      inputRef,
      buttonRef,
      popoverRef,
      listBoxRef,
      onFocus,
    },
    ariaState,
  );

  const buttonProps: AriaButtonOptions<"button"> = {
    ...baseButtonProps,
    onPress(e) {
      baseButtonProps.onPress?.(e);
      modalState.open();
      onFocus?.();
    },
  };

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: () => {
      field.onChange({
        ...field,
        inputValue: "",
      });
    },
  });

  const modalState = useModalState({
    parentState: ariaState,
  });

  useImperativeHandle(imperativeRef, () => {
    return {
      open() {
        ariaState.open();
      },
      close() {
        ariaState.close();
      },
      blur() {
        setTimeout(() => inputRef.current?.blur(), 0);
      },
    };
  });

  const elements = (
    <>
      <ListBox
        listboxRef={listBoxRef}
        listBoxProps={listBoxProps}
        options={{
          state: ariaState,
          getOption(key) {
            return flatOptions.find(
              (flatOption) => toKeyString(flatOption.option.key) === key,
            )?.option.key;
          },
          renderItem,
          overlayOptions: {
            close: ariaState.close,
          },
        }}
      />
      {!isLoading && notFoundLabel && ariaState.collection.size === 0 && (
        <div css={cssFns.padding(16)}>{notFoundLabel}</div>
      )}
      <InView
        as="div"
        skip={isLoading}
        onChange={(view) => {
          if (view) {
            onBottomReached?.();
          }
        }}
        css={cssFns.center()}
      >
        {isLoading && <Spinner styles={cssFns.margin(16)} />}
      </InView>
    </>
  );

  return (
    <>
      <BaseFieldLayout
        fieldRef={fieldRef}
        label={
          label && (
            <FieldLabel
              labelProps={labelProps}
              disabled={field.disabled || disableLabelFocus}
              required={field.required}
            >
              {label}
            </FieldLabel>
          )
        }
        input={
          children ? (
            children(inputRef, inputProps, buttonRef, buttonProps)
          ) : (
            <BaseFieldInput
              field={field}
              fieldProps={focusWithinProps}
              input={
                isMobile ? (
                  <TriggerButton ref={buttonRef} triggerProps={buttonProps}>
                    <input
                      {...inputProps}
                      ref={inputRef}
                      placeholder={placeholder}
                      css={[styles, { pointerEvents: "none" }]}
                    />
                  </TriggerButton>
                ) : (
                  <input
                    {...inputProps}
                    ref={inputRef}
                    placeholder={placeholder}
                    css={[styles, externalStyles]}
                  />
                )
              }
              onClear={
                clearable && field.value
                  ? () => {
                      field.onChange({
                        ...field,
                        value: null,
                        inputValue: "",
                        error: undefined,
                      });
                    }
                  : undefined
              }
              startIcon={
                showSearchIcon ? (
                  <MobileMenuSearch color={secondaryColors.magenta} />
                ) : (
                  icon
                )
              }
              endIcon={
                showArrowDown && (
                  <ButtonV2
                    triggerRef={buttonRef}
                    triggerProps={buttonProps}
                    text="combobox trigger button"
                    variant="icon"
                    icon={
                      ariaState.isOpen ? (
                        <DesktopArrowUp color={principalColors.gs8} />
                      ) : (
                        <DesktopArrowDown color={principalColors.gs8} />
                      )
                    }
                  />
                )
              }
              disabled={field.disabled}
            />
          )
        }
        description={
          description && (
            <FieldDescription
              disabled={field.disabled}
              descriptionProps={descriptionProps}
            >
              {description}
            </FieldDescription>
          )
        }
        error={
          field.error?.visible && (
            <FieldError
              errorMessageProps={errorMessageProps}
              errorMessage={field.error.message}
              fieldRequired={field.required}
            />
          )
        }
      />
      {isMobile
        ? modalState.isOpen && (
            <Modal
              modalRef={popoverRef}
              state={modalState}
              onClose={() => {
                modalState.close();
                field.onChange({
                  ...field,
                  inputValue: "",
                });
              }}
              title={
                <div key="input" css={[cssFns.padding(16), { width: "100%" }]}>
                  <TextField
                    icon={
                      showSearchIcon ? (
                        <MobileMenuSearch color={secondaryColors.magenta} />
                      ) : (
                        icon
                      )
                    }
                    autoFocus
                    placeholder={placeholder}
                    field={{
                      value: field.inputValue || "",
                      onChange: (value) => {
                        if ("value" in value) {
                          field.onChange({
                            ...field,
                            inputValue: value.value,
                          });
                        }
                      },
                    }}
                  />
                  {renderContent(mobile?.subtitle, {
                    close() {
                      ariaState.close();
                    },
                  })}
                </div>
              }
              main={elements}
            />
          )
        : ariaState.isOpen && (
            <Popover
              popoverRef={popoverRef}
              triggerRef={fieldRef}
              state={ariaState}
              maxHeight={350}
              styles={css([
                {
                  width: desktop?.width ?? fieldRef.current?.clientWidth,
                },
              ])}
              isNonModal
              preventPageScroll
            >
              {elements}
            </Popover>
          )}
    </>
  );
}

export function useComboboxState<T>(defaultState?: ComboboxState<T>) {
  return useBaseFieldState<ComboboxState<T>>({
    value: null,
    inputValue: "",
    error: undefined,
    ...defaultState,
  });
}
