import "react-toastify/dist/ReactToastify.css";

import { createPortal } from "react-dom";
import { Slide, toast, ToastContainer as BaseContainer } from "react-toastify";

export const ToastContainer = () => {
  return createPortal(
    <BaseContainer
      autoClose={4000}
      hideProgressBar
      transition={Slide}
      position="top-right"
      closeOnClick
      pauseOnHover
      draggable
      theme="colored"
      className="hermes-toast-container"
      css={{ zIndex: 10000, position: "fixed", userSelect: "none" }}
    />,
    document.body,
  );
};

(window as any).toast = toast;
