import { css } from "@emotion/react";
import type { DoctorsListOrder } from "@hermes/api";
import { Flag } from "@hermes/flags";
import { Temporal, useFormatDateTime, useMessage } from "@hermes/intl";
import { searchParamTypes, useSearchParams } from "@hermes/shared";
import {
  ButtonV2,
  type ComboboxImperativeRef,
  ComboBoxMultiSelect,
  cssFns,
  DatePicker,
  fieldState,
  icons,
  type ListBoxOptions,
  Select,
  type SelectImperativeRef,
  type SelectOption,
  truthy,
  useComboboxMultiSelectState,
  useFormField,
  useFormFields,
  usePrincipalColors,
  useScreenDetector,
  useSelectState,
} from "@hermes/ui";
import { type SVGProps, useRef } from "react";

import { useAppConfig } from "#internal/app/config/context";
import { LineCloseIcon } from "#internal/shared/ui/icons";

type Params = {
  sort?: DoctorsListOrder;
  nearme?: boolean;
  date?: Temporal.PlainDate;
  language?: string;
  insurance?: string;
};

const FiltersMobileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <rect width="30" height="30" rx="15" fill="#3683B6" />
      <path
        d="M8.5 12L21.5 12"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50005 18L21.5 18"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="18" cy="12" r="2" fill="#3683B6" stroke="white" />
      <circle cx="12" cy="18" r="2" fill="#3683B6" stroke="white" />
    </svg>
  );
};

const FiltersMobileIconOutlined = (props: SVGProps<SVGSVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
    <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#E7F0F6" />
    <path
      d="M8.5 12L21.5 12"
      stroke="#B2B9BE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 18L21.4999 18"
      stroke="#B2B9BE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="18" cy="12" r="2" fill="white" stroke="#B2B9BE" />
    <circle cx="12" cy="18" r="2" fill="white" stroke="#B2B9BE" />
  </svg>
);

const Sort = ({
  params,
  onParamsChange,
  variant,
}: {
  params: Params;
  onParamsChange: (params: Params) => void;
  variant?: "filter-search" | "filter-outlined";
}) => {
  const message = useMessage();
  const { isMobile, isDesktop } = useScreenDetector();

  const options = [
    {
      value: "default",
      label: message({
        id: "726e1cde-dd98-4290-b4b6-91d57e3d03e2",
        default: "По умолчанию",
      }),
    },
    {
      value: "rating",
      label: message({
        id: "b76fa912-3ad1-4457-9029-b77db3eb7f85",
        default: "Рейтингу",
      }),
    },
    {
      value: "price",
      label: message({
        id: "e3b14f62-8955-4d56-a436-ae2fcccbfda1",
        default: "Цене",
      }),
    },
    {
      value: "experience",
      label: message({
        id: "31554e02-7099-4fae-947c-46cca5595ea4",
        default: "Стажу",
      }),
    },
    isMobile && {
      value: "distance",
      label: message({
        id: "doctors-list.filters.near",
      }),
    },
  ].filter(truthy) as SelectOption<DoctorsListOrder>[];

  const selectRef = useRef<SelectImperativeRef>(null);
  const field = useFormField(
    useSelectState<DoctorsListOrder>({
      value: params.sort || "default",
      parent: params.sort,
    }),
  );
  const value = (isDesktop ? params.sort : field.value) || "default";
  const selected =
    options.find((option) => option.value === value) || options[0];

  const handleChange = (value?: DoctorsListOrder) => {
    selectRef.current?.close();
    onParamsChange({
      sort: value,
      nearme: value === "distance",
    });
  };

  return (
    <Select
      imperativeRef={selectRef}
      closeOnSelect={isDesktop}
      field={{
        value: field.value,
        onChange(state) {
          if (isDesktop) {
            handleChange(fieldState(field, state).value ?? undefined);
          }
          field.onChange(state);
        },
      }}
      options={options}
      mobile={{
        title: message({
          id: "18e53d11-6a9f-4677-8aaf-87e8a63afb1a",
          default: "Сортировать по",
        }),
        height: "auto",
        footer: (
          <div
            css={[
              cssFns.padding(8),
              {
                display: "grid",
                width: "100%",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              },
            ]}
          >
            <ButtonV2
              text={message({
                id: "common.cancel",
                default: "Отменить",
              })}
              variant="outlined"
              onPress={() => {
                selectRef.current?.close();
              }}
            />
            <ButtonV2
              text={message({
                id: "9db59481-22a6-4d7c-806f-ae8f4ff52a34",
                default: "Применить",
              })}
              variant="solid"
              onPress={() => {
                handleChange(field.value ?? undefined);
              }}
            />
          </div>
        ),
      }}
    >
      {(triggerRef, triggerProps) =>
        isMobile ? (
          <ButtonV2
            triggerRef={triggerRef}
            triggerProps={triggerProps}
            variant="icon"
            icon={
              variant === "filter-outlined" ? (
                <FiltersMobileIconOutlined />
              ) : (
                <FiltersMobileIcon />
              )
            }
            text="sort-mobile-button"
          />
        ) : (
          <ButtonV2
            triggerRef={triggerRef}
            triggerProps={triggerProps}
            text={selected?.label || ""}
            variant="filter-outlined-icon"
            active={!!value}
            minWidth="165px"
          />
        )
      }
    </Select>
  );
};

export const DoctorListFilters = ({
  params,
  onParamsChange,
  enableChildFilter,
  isChild,
  onChildChange,
  variant,
}: {
  params: Params;
  onParamsChange: (params: Params) => void;
  enableChildFilter?: boolean;
  isChild?: boolean;
  onChildChange?: () => void;
  variant?: "filter-search" | "filter-outlined";
}) => {
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();
  const message = useMessage();
  const formatDateTime = useFormatDateTime();
  const { insurances, country } = useAppConfig();
  const lngComboRef = useRef<ComboboxImperativeRef>(null);
  const insuranceComboRef = useRef<ComboboxImperativeRef>(null);

  const fields = useFormFields({
    languages: useComboboxMultiSelectState({
      value: params.language
        ? country.doctorLocales
            .filter(
              ({ value }) =>
                !!params.language
                  ?.split(",")
                  .find((code: string) => code === value),
            )
            .map((lng) => ({
              key: lng,
              label: lng.title,
            }))
        : [],
    }),
    insurances: useComboboxMultiSelectState({
      value: params.insurance
        ? insurances
            .filter(
              ({ id }) =>
                !!params.insurance
                  ?.split(",")
                  .find((num: string) => Number(num) === id),
            )
            .map((insurance) => ({
              key: insurance,
              label: insurance.name,
            }))
        : [],
    }),
  });

  return (
    <div
      css={[
        isMobile && [
          cssFns.padding(10),
          {
            backgroundColor: principalColors.ebblue,
            paddingInlineEnd: 0,
          },
        ],
        {
          display: "flex",
          alignItems: "center",
          columnGap: isMobile ? "6px" : "16px",
          overflowX: "auto",
          scrollbarWidth: "none",
        },
      ]}
    >
      <Sort params={params} onParamsChange={onParamsChange} variant={variant} />
      {enableChildFilter && (
        <Flag
          id="1a7d4b25-6711-4f32-8118-ba3a5556d167"
          description="Enable children filter in doctors list page"
        >
          <ButtonV2
            text={message({
              id: "doctors-list.filters.children",
              default: "Детский",
            })}
            variant={
              variant ? variant : isMobile ? "filter-search" : "filter-outlined"
            }
            active={isChild}
            id="isForChildren"
            onPress={onChildChange}
          />
        </Flag>
      )}
      <Flag
        id="635e3e5e-50c6-47f6-b2d3-fdf24547c652"
        description="Enable near filter in doctors list page"
      >
        <ButtonV2
          text={message({
            id: "doctors-list.filters.near",
            default: "Рядом",
          })}
          variant={
            variant ? variant : isMobile ? "filter-search" : "filter-outlined"
          }
          active={params.nearme}
          onPress={() => {
            onParamsChange({
              nearme: !params.nearme,
              sort: undefined,
            });
          }}
        />
      </Flag>
      <Flag
        id="ff5b8251-73f3-43b0-91c6-205bd5d4b3ba"
        description="Enable date filter in doctors list page"
      >
        <DatePicker
          value={params.date}
          onChange={(value) => {
            onParamsChange({ date: value });
          }}
          disabled={{
            before: new Date(),
          }}
        >
          {(triggerRef, triggerProps) => (
            <ButtonV2
              text={
                params.date
                  ? formatDateTime(params.date, {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })
                  : message({
                      id: "common.fields.appointment-date",
                      default: "Дата приема",
                    })
              }
              variant={
                variant
                  ? variant
                  : isMobile
                    ? "filter-search"
                    : "filter-outlined"
              }
              triggerRef={triggerRef}
              triggerProps={triggerProps}
              active={!!params.date}
              icon={
                params.date ? (
                  <LineCloseIcon
                    width={isMobile ? "20" : "24"}
                    height={isMobile ? "20" : "24"}
                    color={
                      isMobile ? principalColors.white : principalColors.gs8
                    }
                  />
                ) : undefined
              }
              onIconPress={
                params.date
                  ? () => {
                      onParamsChange({ date: undefined });
                    }
                  : undefined
              }
            />
          )}
        </DatePicker>
      </Flag>

      <Flag id="language-filter">
        <ComboBoxMultiSelect
          imperativeRef={lngComboRef}
          variant="column"
          stateless
          field={fields.languages}
          mobile={{
            subtitle: message({
              id: "c8176565-1299-4e34-b908-1eae7192580e",
              default: "Язык",
            }),
          }}
          desktop={{
            width: "200px",
          }}
          options={country.doctorLocales.map((lng) => ({
            key: lng,
            label: lng.title,
          }))}
          toKeyString={(lng) => lng.value}
          onSelectionChange={(option) => {
            const options = option?.map((opt) => opt.key.value);

            onParamsChange({
              language: options?.join(",") || undefined,
            });

            setTimeout(() => lngComboRef.current?.close(), 0);
          }}
          renderItem={LanguageOptionItem}
          renderSelectedItems={() => null}
          children={(inputRef, inputProps, triggerRef, triggerProps) => (
            <>
              <ButtonV2
                text={
                  params.language
                    ? `${message({ id: "c8176565-1299-4e34-b908-1eae7192580e" })}: ${params.language.split(",").length}`
                    : message({
                        id: "c8176565-1299-4e34-b908-1eae7192580e",
                      })
                }
                variant={
                  variant
                    ? variant
                    : isMobile
                      ? "filter-search"
                      : "filter-outlined"
                }
                active={!!params.language}
                triggerRef={triggerRef}
                triggerProps={triggerProps}
                icon={
                  params.language ? (
                    <LineCloseIcon
                      width={isMobile ? "20" : "24"}
                      height={isMobile ? "20" : "24"}
                      color={
                        isMobile ? principalColors.white : principalColors.gs8
                      }
                    />
                  ) : undefined
                }
                onIconPress={
                  params.language
                    ? () => {
                        onParamsChange({ language: undefined });
                        fields.languages.onChange({
                          ...fields.languages,
                          value: [],
                        });
                      }
                    : undefined
                }
                styles={css({
                  width: "auto",
                  ...(!isMobile && {
                    background:
                      triggerProps["aria-expanded"] ||
                      (fields.languages.value &&
                        fields.languages.value.length > 0)
                        ? principalColors.grayline
                        : principalColors.white,
                  }),
                })}
              />
              <input ref={inputRef} {...inputProps} css={{ display: "none" }} />
            </>
          )}
        />
      </Flag>
      {insurances.length > 0 && (
        <ComboBoxMultiSelect
          imperativeRef={insuranceComboRef}
          variant="column"
          stateless
          field={fields.insurances}
          mobile={{
            subtitle: message({
              id: "1b37bfea-a8ef-4af1-a951-2e761223461c",
              default: "Insurance",
            }),
          }}
          desktop={{
            width: "200px",
          }}
          options={insurances.map((insurance) => ({
            key: insurance,
            label: insurance.name,
          }))}
          toKeyString={(insurance) => insurance.id.toString()}
          onSelectionChange={(option) => {
            const options = option?.map((opt) => opt.key.id.toString());

            onParamsChange({
              insurance: options?.join(",") || undefined,
            });

            setTimeout(() => insuranceComboRef.current?.close(), 0);
          }}
          renderItem={InsuranceOptionItem}
          renderSelectedItems={() => null}
          children={(inputRef, inputProps, buttonRef, buttonProps) => (
            <>
              <ButtonV2
                text={
                  params.insurance
                    ? `${message({ id: "1b37bfea-a8ef-4af1-a951-2e761223461c" })}: ${params.insurance.split(",").length}`
                    : message({
                        id: "1b37bfea-a8ef-4af1-a951-2e761223461c",
                      })
                }
                variant={
                  variant
                    ? variant
                    : isMobile
                      ? "filter-search"
                      : "filter-outlined"
                }
                active={!!params.insurance}
                triggerRef={buttonRef}
                triggerProps={buttonProps}
                icon={
                  params.insurance ? (
                    <LineCloseIcon
                      width={isMobile ? "20" : "24"}
                      height={isMobile ? "20" : "24"}
                      color={
                        isMobile ? principalColors.white : principalColors.gs8
                      }
                    />
                  ) : undefined
                }
                onIconPress={
                  params.insurance
                    ? () => {
                        onParamsChange({ insurance: undefined });
                        fields.insurances.onChange({
                          ...fields.insurances,
                          value: [],
                        });
                      }
                    : undefined
                }
                styles={css({
                  width: "auto",
                  ...(!isMobile && {
                    background:
                      buttonProps["aria-expanded"] ||
                      (fields.insurances.value &&
                        fields.insurances.value.length > 0)
                        ? principalColors.grayline
                        : principalColors.white,
                  }),
                })}
              />
              <input ref={inputRef} {...inputProps} css={{ display: "none" }} />
            </>
          )}
        />
      )}
    </div>
  );
};

const LanguageOptionItem: ListBoxOptions<any>["renderItem"] = ({
  option,
  rendered,
}) => {
  const [params] = useSearchParams(
    {
      language: searchParamTypes.string({}),
    },
    { allowUnknown: true },
  );

  const checked = params.language
    ? !!params.language
        ?.split(",")
        .find((code: string) => code === option.value)
    : false;

  return <Checkbox checked={checked} rendered={rendered} />;
};

const InsuranceOptionItem: ListBoxOptions<any>["renderItem"] = ({
  option,
  rendered,
}) => {
  const [params] = useSearchParams(
    {
      insurance: searchParamTypes.string({}),
    },
    { allowUnknown: true },
  );

  const checked = params.insurance
    ? !!params.insurance
        ?.split(",")
        .find((id: string) => Number(id) === option.id)
    : false;

  return <Checkbox checked={checked} rendered={rendered} />;
};

const Checkbox = ({
  checked,
  rendered,
}: {
  checked: boolean;
  rendered: React.ReactNode;
}) => {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();

  return (
    <div
      css={{
        padding: isMobile ? "0 10px" : "0 8px",
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "flex-start",
          gap: 10,
          padding: isMobile ? "16px 0 15px" : "8px 0 8px",
          borderBottom: isMobile
            ? `1px solid ${principalColors.grayline}`
            : "none",
          svg: {
            minWidth: "24px",
            color: checked ? principalColors.ebblue : principalColors.grayline,
          },
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        {checked ? <icons.CheckboxFilled /> : <icons.CheckboxNormal />}{" "}
        {rendered}
      </div>
    </div>
  );
};
