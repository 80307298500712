import { css } from "@emotion/react";
import { type ReactNode, useEffect, useRef, useState } from "react";

type TooltipProps = {
  text: string;
  children: ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  offset?: number;
  delay?: number;
};

export const Tooltip = ({
  text,
  children,
  position = "top",
  offset = 8,
  delay = 200,
}: TooltipProps) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const timer = useRef<number | null>(null);

  const showTooltip = () => {
    if (timer.current) clearTimeout(timer.current);
    setVisible(true);
  };

  const hideTooltip = () => {
    timer.current = setTimeout(() => setVisible(false), delay);
  };

  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  return (
    <div
      css={tooltipContainer}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
      tabIndex={0}
    >
      {children}
      {visible && (
        <div
          ref={tooltipRef}
          css={[tooltipStyles, tooltipPositions[position](offset)]}
        >
          {text}
        </div>
      )}
    </div>
  );
};

const tooltipContainer = css`
  display: inline-block;
  position: relative;
`;

const tooltipStyles = css`
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 100;
  transition: opacity 0.2s ease-in-out;
  min-width: 200px;
`;

const tooltipPositions = {
  top: (offset: number) => css`
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: ${offset}px;
  `,
  bottom: (offset: number) => css`
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: ${offset}px;
  `,
  left: (offset: number) => css`
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: ${offset}px;
  `,
  right: (offset: number) => css`
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: ${offset}px;
  `,
};
