import { css } from "@emotion/react";
import {
  type DoctorsListOrder,
  useV1DoctorsListMeta,
  useV1ServicesSuspense,
  useV1ServiceSuspense,
} from "@hermes/api";
import { useFlag } from "@hermes/flags";
import { Message, useMessage } from "@hermes/intl";
import {
  searchParamTypes as t,
  toast,
  useGeolocation,
  useSearchParams,
} from "@hermes/shared";
import {
  cssFns,
  LayoutContainer,
  truthy,
  unique,
  usePrincipalColors,
  useScreenDetector,
  useSecondaryColors,
} from "@hermes/ui";
import { startTransition, useEffect } from "react";
import {
  Navigate,
  useNavigate,
  useParams as useRouteParams,
} from "react-router-dom";

import { useAppConfig } from "#internal/app/config/context";
import { useRoutePath } from "#internal/app/router";
import { DoctorsList } from "#internal/features/doctors-list";
import { SurveyBanner } from "#internal/features/survey-banner";
import { AppHelmet } from "#internal/helmet";
import { usePrerenderReady } from "#internal/shared/utils";

import { DoctorListFilters } from "./filters";
import { DoctorListFooter } from "./footer";
import { DoctorListHeader } from "./header";

const useParams = () => {
  const [params, setParams] = useSearchParams(
    {
      sort: t.string<DoctorsListOrder | undefined>({
        enum: ["default", "rating", "price", "experience"],
      }),
      nearme: t.boolean(),
      date: t.plainDate(),
      city_area: t.string({}),
      language: t.string({}),
      insurance: t.string({}),
    },
    { allowUnknown: true },
  );

  return [
    {
      nearme: params.nearme,
      sort: params.nearme
        ? "distance"
        : (params.sort as DoctorsListOrder | undefined),
      date: params.date,
      city_area: params.city_area,
      language: params.language,
      insurance: params.insurance,
    },
    setParams,
  ] as const;
};

const feedbackThreshold = Number(import.meta.env.DOQ_FEEDBACK_THRESHOLD);

export const DoctorsListPage = () => {
  usePrerenderReady();
  const { routePath } = useRoutePath();
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const navigate = useNavigate();
  const message = useMessage();
  const flag = useFlag();
  const { city, cityArea, changeSearchLabel } = useAppConfig();

  const routeParams = useRouteParams();
  const serviceSlug = routeParams["*"]?.endsWith("/")
    ? routeParams["*"].slice(0, -1)
    : routeParams["*"];

  if (!serviceSlug) {
    return <Navigate to={routePath({ path: "/404" })} replace />;
  }

  const [params, setParams] = useParams();
  const { position, error } = useGeolocation({ enabled: params.nearme });

  useEffect(() => {
    if (!cityArea?.slug) return;
    if (cityArea.slug !== params.city_area) {
      setParams((schema) => ({ ...schema, city_area: cityArea.slug }));
    }
  }, [cityArea?.slug, params.city_area]);

  useEffect(() => {
    if (error === undefined) return;
    setParams((schema) => ({ ...schema, nearme: false }));
    toast.warn(
      message({
        id: "cde077f9-d579-44d4-a1e9-1fefaaf66655",
        default:
          "Не можем определить ваше местоположение. Пожалуйста, дайте доступ к вашей геолокации",
      }),
    );
  }, [error?.code]);

  const { data: serviceData } = useV1ServiceSuspense({
    slug: serviceSlug,
    expand: ["extra_services", "adult_service", "children_service"],
  });

  if (serviceData.ok === false) {
    return <Navigate to={routePath({ path: "/404" })} replace />;
  }

  const currentService = serviceData.data;
  const isChild = Boolean(currentService.adult_service);
  const hasChild = Boolean(currentService.children_service) || isChild;

  const adultService = isChild
    ? typeof currentService.adult_service === "object"
      ? currentService.adult_service
      : null
    : currentService;
  const childService = isChild
    ? currentService
    : typeof currentService.children_service === "object"
      ? currentService.children_service
      : null;

  const handleChildChange = () => {
    navigate(
      routePath({
        path: "/doctors/:city/:service",
        params: {
          city: city.slug,
          service:
            (isChild ? adultService?.slug : childService?.slug) ??
            currentService.slug,
        },
        query: {
          city_area: params.city_area,
          date: params.date?.toString(),
          nearme: params.nearme,
          sort: params.sort,
          language: params.language,
          insurance: params.insurance,
        },
      }),
    );
  };

  const extraServicesIds = (
    isChild
      ? currentService.extra_services
      : [
          ...currentService.extra_services,
          ...(childService?.extra_services || []),
        ]
  )
    .filter(truthy)
    .filter(unique());

  const { data: servicesData } = useV1ServicesSuspense({
    id: extraServicesIds,
  });
  const services = [
    currentService,
    childService,
    ...extraServicesIds.map((id) => {
      const target = servicesData.results.find((service) => service.id === id);

      if (!target) return undefined;
      return {
        ...target,
        id,
      };
    }),
  ]
    .filter(truthy)
    .filter(unique((service) => service.id.toString()));

  const { data: meta, isLoading: isMetaLoading } = useV1DoctorsListMeta(
    {
      ordering: params.sort,
      city: city.id,
      city_area: cityArea?.id,
      service: services.map((service) => service.id),
      lat: position?.lat,
      lng: position?.lng,
      slot_date: params.date?.toString(),
      language: params.language,
      insurance: params.insurance,
    },
    {
      throwOnError: false,
      retry: false,
    },
  );

  if (meta && !meta.ok) {
    return <Navigate to={routePath({ path: "/404" })} replace />;
  }

  useEffect(() => {
    if (typeof meta === "undefined") {
      changeSearchLabel({ text: "", isLoading: true });
      return;
    }

    const label = `${currentService.alternate_name || currentService.name} (${meta.count})`;
    changeSearchLabel({ text: label });
  }, [currentService.name, meta?.count]);

  const isNoIndex = !!(
    (params.sort && params.sort !== "default") ||
    params.date ||
    (params.insurance &&
      !flag({ id: "doctors-list.disable-no-index-on-insurance" })) ||
    params.language ||
    (cityArea && !cityArea.sitemap_index)
  );

  const displayAllReviews = meta
    ? meta.feedback_count / Math.max(1, meta.count) <= feedbackThreshold
    : false;

  return (
    <>
      <AppHelmet
        key={JSON.stringify(meta)}
        title={meta?.meta_title}
        description={meta?.meta_description}
        isDoctorsAggregateRating
        feedbackScore={meta?.feedback_score}
        feedbackCount={meta?.feedback_count}
        maxPrice={meta?.max_price}
        minPrice={meta?.min_price}
        currency={meta?.currency}
        isNoIndex={isNoIndex}
      />
      <DoctorListHeader
        isMetaLoading={isMetaLoading}
        service={currentService}
        meta={meta}
        filters={
          <DoctorListFilters
            params={params}
            onParamsChange={(newParams) => {
              startTransition(() => {
                setParams({
                  ...params,
                  ...newParams,
                });
              });
            }}
            isChild={isChild}
            enableChildFilter={hasChild && meta?.count !== 0}
            onChildChange={handleChildChange}
          />
        }
      />
      <LayoutContainer
        styles={css([
          cssFns.padding(16, 12),
          isMobile
            ? cssFns.typo({ weight: "semibold", level: "body-1" })
            : cssFns.typo({ weight: "semibold", level: "title-3" }),
          {
            color: principalColors.white,
            background: cssFns.linearGradient("90deg", [
              secondaryColors.midgreen,
              principalColors.ebblue,
            ]),
            textAlign: isMobile ? "center" : "start",
          },
        ])}
      >
        <Message id="common.banner-title" />
      </LayoutContainer>
      <LayoutContainer
        styles={css([
          isMobile && cssFns.padding(0, 10),
          { marginBlock: 20 },
          { flexGrow: 1 },
        ])}
      >
        <DoctorsList
          slotDate={params.date}
          sort={params.sort}
          targetServices={services}
          city={city}
          cityArea={cityArea}
          position={position}
          enablePromo
          pageHeader={currentService.name}
          language={params.language}
          insurance={params.insurance}
          currentService={currentService}
          displayAllReviews={displayAllReviews}
        />
      </LayoutContainer>
      <DoctorListFooter
        isMetaLoading={isMetaLoading}
        service={currentService}
        meta={meta}
      />
      <SurveyBanner />
    </>
  );
};
