import { IntlMessageFormat } from "intl-messageformat";
import {
  createContext,
  type ReactElement,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { useLocale } from "./locale";

type Messages = {
  [id: string]: string;
};

export class MessageCache {
  private _load: (key: string) => Promise<Messages> | undefined;
  private _data = new Map<
    unknown,
    | { status: "pending"; payload: Promise<Messages> }
    | { status: "success"; payload: Messages }
    | { status: "failure"; payload: Error }
  >();

  constructor({
    load,
  }: {
    load: (key: string) => Promise<Messages> | undefined;
  }) {
    this._load = load;
  }

  get(locale: Intl.Locale) {
    const entry = this._data.get(locale.baseName);
    switch (entry?.status) {
      case "pending":
        throw entry.payload.then(() => {});
      case "success":
        return entry.payload;
      case "failure":
        throw entry.payload;
      case undefined: {
        const key = locale.baseName;
        const promise = this._load(key);
        if (!promise) return undefined;
        throw promise.then(
          (result) => {
            this._data.set(key, { status: "success", payload: result });
          },
          (error) => {
            this._data.set(key, { status: "failure", payload: error });
            throw error;
          },
        );
      }
    }
  }

  static getAll(locale: Intl.Locale, caches: MessageCache[]) {
    Promise.allSettled(caches.map((cache) => cache.get(locale)));
  }
}

const Context = createContext<
  | {
      caches: MessageCache[];
      messages: Messages | undefined;
      sourceLocale: Intl.Locale;
    }
  | undefined
>(undefined);

export function MessageLoader({
  caches,
  children,
  sourceLocale,
}: {
  caches: MessageCache[];
  children: ReactNode;
  sourceLocale: Intl.Locale | string;
}): ReactElement {
  const [locale] = useLocale();
  const value = useMemo(() => {
    const sLocale = new Intl.Locale(sourceLocale);

    MessageCache.getAll(locale, caches);

    return {
      caches,
      messages: caches
        .map((cache) => cache.get(locale))
        .reduce((messages, acc) => ({ ...acc, ...messages }), {}),
      sourceLocale: sLocale,
    };
  }, [caches, locale, sourceLocale]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const Message = (params: {
  id: string;
  default?: string;
  values?: { [name: string]: unknown };
}) => {
  return <>{useMessage()(params)}</>;
};

export const useMessage = () => {
  const [locale] = useLocale();
  const context = useContext(Context);
  return useCallback(
    ({
      values,
      ...params
    }: {
      id: string;
      default?: string;
      values?: { [name: string]: unknown };
    }): string => {
      // Has translation
      try {
        const m = context?.messages?.[params.id];
        if (m) {
          if (values) {
            const mf = new IntlMessageFormat(m, locale.baseName);
            return mf.format(values) as string;
          }
          return m;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }

      // Have default
      try {
        if (params.default) {
          if (values) {
            const mf = new IntlMessageFormat(
              params.default,
              context?.sourceLocale.baseName ?? locale.baseName,
            );
            return mf.format(values) as string;
          }
          return params.default;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }

      return params.default || params.id;
    },
    [context, locale.baseName],
  );
};
