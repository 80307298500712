import {
  type AppointmentField,
  type DoctorService,
  useV1ClinicBranchAppointmentFieldsSuspense,
} from "@hermes/api";
import { Temporal, useMessage } from "@hermes/intl";
import {
  Checkbox,
  EmailField,
  type Field,
  type FieldState,
  type FormErrorsContainer,
  type FormFields,
  LinearDateField,
  NumberField,
  RadioGroup,
  TextField,
  truthy,
  useCheckboxState,
  useFormFields,
  useLinearDateFieldState,
  useNumberFieldState,
  useRadioGroupState,
  useTextFieldState,
} from "@hermes/ui";

import type { BranchServices } from "../doctor-card";
import { isChildService } from "./child";

export const useAdditionalFields = ({
  errors,
  branch_services,
  selectedService,
}: {
  errors: FormErrorsContainer;
  branch_services: BranchServices;
  selectedService?: DoctorService;
}) => {
  const message = useMessage();

  const { data: appointment_fields } =
    useV1ClinicBranchAppointmentFieldsSuspense({
      id: branch_services.branch.id,
    });

  const additionalFields: FormFields<{
    [x: string]: Field<FieldState<any>>;
  }> = useFormFields(
    Object.fromEntries(
      appointment_fields
        .map((field) => {
          const type = Object.values(field.type)[0];

          if (type === "Integer" || type === "Целое") {
            return [
              field.id.toString(),
              useNumberFieldState({
                value: NaN,
                required: field.is_required,
                error: errors.get(field.id.toString()),
                rules: [
                  (v) => (field.max_length ? v <= field.max_length : true),
                  (v) => (field.min_length ? v >= field.min_length : true),
                ],
              }),
            ];
          }

          if (type === "Boolean") {
            return [
              field.id.toString(),
              useCheckboxState({
                value: false,
                required: field.is_required,
              }),
            ];
          }

          if (type === "Date") {
            return [
              field.id.toString(),
              useLinearDateFieldState({
                value: null,
                required: field.is_required,
                rules: [
                  (value) => {
                    if (!value || value.trim() === "") {
                      return false;
                    }
                    const isChild = selectedService
                      ? isChildService(selectedService)
                      : undefined;
                    if (field.param_name === "patientDateOfBirth" && isChild) {
                      const birthDate = Temporal.PlainDate.from(value);
                      const today = Temporal.Now.plainDateISO();
                      const months = today
                        .since(birthDate)
                        .total({ unit: "months", relativeTo: birthDate });

                      if (months > 216) {
                        return message({
                          id: "common.form.additional-fields.wrong-date",
                          default: "Неверная {date}",
                          values: { date: field.title },
                        });
                      }

                      if (months <= 216) {
                        return message({
                          id: "common.form.additional-fields.wrong-date",
                          values: { date: field.title },
                        });
                      }
                    }
                    return true;
                  },
                ],
                error: errors.get(field.id.toString()),
              }),
            ];
          }

          if (field.param_name === "patientEmail") {
            return [
              field.id.toString(),
              useTextFieldState({
                value: "",
                required: field.is_required,
                rules: [
                  (value) => {
                    if (value.trim() === "") {
                      return false;
                    }
                    const emailRegex = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/;
                    if (!emailRegex.test(value))
                      return message({
                        id: "common.form.additional-fields.wrong-email",
                        default: "Неверный {email}",
                        values: { email: field.title },
                      });
                    return true;
                  },
                ],
                error: errors.get(field.id.toString()),
              }),
            ];
          }

          if (field.param_name === "gender") {
            return [
              field.id.toString(),
              useRadioGroupState({
                value: null,
                required: field.is_required,
              }),
            ];
          }

          const stringLengthRules = [
            (v: string) =>
              field.max_length
                ? v.length <= field.max_length ||
                  message({
                    id: "common.errors.max-length",
                    default: "Maximum {n} symbols",
                    values: {
                      n: field.max_length,
                    },
                  })
                : true,
            (v: string) =>
              field.min_length
                ? v.length >= field.min_length ||
                  message({
                    id: "common.errors.min-length",
                    default: "Minimum {n} symbols",
                    values: {
                      n: field.min_length,
                    },
                  })
                : true,
          ];

          if (field.param_name === "patientTAJ") {
            return [
              field.id.toString(),
              useTextFieldState({
                value: "",
                required: field.is_required,
                rules: stringLengthRules,
                error: errors.get("taj"),
              }),
            ];
          }

          return [
            field.id.toString(),
            useTextFieldState({
              value: "",
              required: field.is_required,
              rules: stringLengthRules,
              error: errors.get(field.id.toString()),
            }),
          ];
        })
        .filter(truthy),
    ),
  );

  return {
    additionalFields,
    appointment_fields,
  };
};

export const AdditionalField = ({
  field,
  info,
}: {
  field: Field<FieldState<any>>;
  info: AppointmentField;
}) => {
  const message = useMessage();
  const type = Object.values(info.type)[0];

  if (type === "Integer" || type === "Целое") {
    return (
      <NumberField
        key={info.id}
        field={field}
        label={info.title}
        maxValue={info.max_length}
        minValue={info.min_length}
        formatOptions={{
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "decimal",
          useGrouping: false,
        }}
        disableLabelFocus
      />
    );
  }

  if (type === "Boolean") {
    return <Checkbox key={info.id} field={field} label={info.title} />;
  }

  if (type === "Date") {
    return (
      <LinearDateField
        key={info.id}
        field={field}
        label={info.title}
        disableLabelFocus
      />
    );
  }

  if (info.param_name === "patientEmail") {
    return <EmailField field={field} label={info.title} disableLabelFocus />;
  }

  if (info.param_name === "gender") {
    const genderTypes = [
      {
        id: "male",
        name: message({ id: "common.form.gender.male", default: "Мужской" }),
      },
      {
        id: "female",
        name: message({ id: "common.form.gender.female", default: "Женский" }),
      },
      {
        id: "",
        name: message({ id: "common.form.gender.other", default: "Другой" }),
      },
    ];
    return (
      <RadioGroup
        label={message({ id: "common.form.gender.label", default: "Пол" })}
        variant="inline"
        field={field}
        options={genderTypes.map((type) => ({
          label: type.name,
          value: type.id,
        }))}
        disableLabelFocus
      />
    );
  }

  return (
    <TextField
      key={info.id}
      field={field}
      label={info.title}
      maxLength={info.max_length}
      minLength={info.min_length}
      disableLabelFocus
    />
  );
};
