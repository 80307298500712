import type { SerializedStyles } from "@emotion/react";
import type { ReactNode } from "react";

import { useScreenDetector } from "../screen-type";

export const LayoutContainer = ({
  styles,
  children,
}: {
  styles?: SerializedStyles;
  children?: ReactNode;
}) => {
  const { isDesktop } = useScreenDetector();
  const maxWidth = isDesktop ? "1010px" : undefined;

  return (
    <div
      css={[
        styles,
        isDesktop && {
          display: "grid",
          justifyItems: "center",
        },
      ]}
    >
      <div css={{ width: maxWidth, display: "flex", flexDirection: "column" }}>
        {children}
      </div>
    </div>
  );
};
