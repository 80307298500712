import type { DoctorsListOrder } from "@hermes/api";

export enum RoutesIdEnum {
  HOME = "home",
  DOCTORS_LIST = "doctors-list",
  DOCTOR_PROFILE = "doctor-profile",
  CLINICS_LIST = "clinics-list",
  CLINIC_PROFILE = "clinic-profile",
  PROCEDURES_LIST = "procedures-list",
  PROCEDURES_BY_CATEGORIES = "procedures-by-category",
  APPOINTMENT_PAGE = "appointment-page",
  FEEDBACK_PAGE = "feedback-page",
  MAP_PAGE = "map-page",
  NOT_FOUND_PAGE = "not-found-page",
  CONTACTS_PAGE = "contacts-page",
  JOIN_CLINIC = "join-clinic",
  IMPRESSZUM = "impresszum",
  USER_GTC = "user-gtc",
  PARTNER_GTC = "partner-gtc",
  PRIVACY_POLICY = "privacy-policy",
  PROCESSING_AGREEMENT = "processing-agreement",
  SURVEY = "survey",
}

export type ExactRoute =
  | {
      path: "/";
    }
  | {
      path: "/doctors/:city/:service";
      params: {
        city: string;
        service: string;
      };
      query?: {
        date?: string;
        city_area?: string;
        nearme?: boolean;
        sort?: DoctorsListOrder;
        language?: string;
        insurance?: number;
      };
    }
  | {
      path: "/doctor/:slug";
      params: {
        slug: string;
      };
      query?: {
        doctor_service_id?: string;
        with_all_reviews?: boolean;
      };
    }
  | {
      path: "/clinics/:city";
      params: {
        city: string;
      };
    }
  | {
      path: "/procedures/:city";
      params: {
        city: string;
      };
    }
  | {
      path: "/procedures/:city/categories";
      params: {
        city: string;
      };
      query?: {
        category_id?: string;
      };
    }
  | {
      path: "/map";
      query: {
        lat: number;
        lng: number;
      };
    }
  | {
      path: "/clinics/:city/:slug";
      params: {
        city: string;
        slug: string;
      };
    }
  | {
      path: "/procedures/:city/:slug";
      params: {
        city: string;
        slug: string;
      };
    }
  | {
      path: "/404";
    }
  | {
      path: "/appointments/:appointment_id";
      params: {
        appointment_id: string;
      };
      query: {
        hash: string;
      };
    }
  | {
      path: "/feedback/:appointment_id";
      params: {
        appointment_id: number;
      };
      query: {
        hash: string;
      };
    }
  | {
      path: "/processing-agreement";
    }
  | {
      path: "/contacts";
    }
  | {
      path: "/join";
    }
  | {
      path: "/survey";
    }
  | {
      path: "/impresszum";
    }
  | {
      path: "/privacy_policy";
    }
  | {
      path: "/user_gtc";
    }
  | {
      path: "/partner_gtc";
    };
