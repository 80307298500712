import { css } from "@emotion/react";
import { type ReactNode, useState } from "react";

type TabItem = {
  label: string;
  key: string | number;
  content: ReactNode;
};

type TabsProps = {
  tabs: TabItem[];
  defaultActiveKey?: string | number;
};

export const Tabs = ({ tabs, defaultActiveKey }: TabsProps) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey ?? tabs[0]?.key);

  return (
    <div>
      <div css={tabsHeaderStyles}>
        {tabs.map((tab) => (
          <button
            key={tab.key}
            css={[tabButtonStyles, activeKey === tab.key && activeTabStyles]}
            onClick={() => setActiveKey(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div css={tabContentStyles}>
        {tabs.find((tab) => tab.key === activeKey)?.content}
      </div>
    </div>
  );
};

const tabsHeaderStyles = css`
  display: flex;
  border-bottom: 2px solid #ddd;
`;

const tabButtonStyles = css`
  background: none;
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  font-size: 16px;
  color: #777;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #333;
  }
`;

const activeTabStyles = css`
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid #000;
`;

const tabContentStyles = css`
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
`;
