import { type SerializedStyles } from "@emotion/react";
import type { City, CityArea, Doctor } from "@hermes/api";
import {
  cssFns,
  truthy,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";

import { DoctorProfileReview } from "#internal/features/doctor-card/profile-review";

import type { BranchServices } from "../doctor-card";
import { DoctorClinicInfo } from "../doctor-card/clinic-info";
import { DoctorProfileInfoDetails } from "../doctor-card/profile-info-details";

export const ModalHeader = ({
  doctor,
  city,
  cityArea,
  branch_services,
  styles,
}: {
  doctor: Doctor;
  city?: City;
  cityArea?: CityArea;
  branch_services: BranchServices;
  styles?: SerializedStyles;
}) => {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();

  return (
    <div
      css={[
        styles,
        {
          display: "flex",
          gap: isMobile ? 12 : 20,
        },
      ]}
    >
      <DoctorProfileReview doctor={doctor} disableReview />
      <div
        css={{
          maxWidth: 400,
        }}
      >
        <h3
          css={[
            cssFns.typo({ level: "body-1", weight: "semibold" }),
            { color: principalColors.gs2, marginBlockEnd: 6 },
          ]}
        >
          {doctor.name}
        </h3>
        <DoctorProfileInfoDetails
          services={branch_services.services
            .filter((service) => service.service_type === "initial-appointment")
            .map((service) => service.service_name)
            .filter(truthy)}
          science_degree={doctor.science_degree}
          academic_status={doctor.academic_status}
          experience={doctor.experience}
        />
        <DoctorClinicInfo
          address={branch_services.branch.address}
          city={city}
          cityArea={cityArea}
        />
      </div>
    </div>
  );
};
