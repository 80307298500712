import { css } from "@emotion/react";
import {
  useV1AppointmentCreate,
  useV1AppointmentValidate,
  type UseV1AppointmentValidateParams,
  useV1DoctorsServicesListSuspense,
} from "@hermes/api";
import type { GenericErrorResponse } from "@hermes/api/src/errors";
import { useFlag } from "@hermes/flags";
import { useMessage } from "@hermes/intl";
import { toast } from "@hermes/shared";
import {
  ButtonV2,
  type Field,
  Form,
  type FormFields,
  Modal,
  truthy,
  useErrors,
  useScreenDetector,
} from "@hermes/ui";
import { captureException, captureMessage } from "@sentry/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAppConfig } from "#internal/app/config/context";
import { useGClientInfo, useSendGTagEvent } from "#internal/app/gtag";
import { useRoutePath } from "#internal/app/router";

import type { AppointmentModalProps } from ".";
import { useAdditionalFields } from "./additional-fields";
import { ModalBodyContainer } from "./container";
import { AppointmentCreateError, AppointmentValidateError } from "./errors";
import { AppointmentForm } from "./form";
import { ModalHeader } from "./header";
import {
  useAppointmentFormFields,
  useAppointmentInsuranceFields,
  useAppointmentOtpFields,
} from "./hooks/use-fields";
import { AppointmentInfoBlock } from "./info-block";
import { AppointmentLegalsBlock } from "./legals-block";
import { OtpStep, useSendOtp } from "./otp";
import { PatientAgeBlock } from "./patient-age";
import { ModalPrice } from "./price";
import { SlotsBlock } from "./slots-block";

export const AppointmentModalAsyncContent = ({
  branch_services,
  startDate,
  endDate,
  doctor,
  branchSlots,
  city,
  cityArea,
  initialSlot,
  request_meta,
}: AppointmentModalProps) => {
  const { routePath } = useRoutePath();
  const { isMobile } = useScreenDetector();
  const navigate = useNavigate();
  const message = useMessage();
  const { getGAClientInfo } = useGClientInfo();
  const { sendGTagEvent } = useSendGTagEvent();
  const { insurances, country } = useAppConfig();

  const { data: doctorServicesData } = useV1DoctorsServicesListSuspense({
    doctor: doctor.id,
    clinic_branch: [branch_services.branch.id],
    expand: ["service_name", "service_type", "service_slug"],
  });
  const doctorServicesList = doctorServicesData.results;

  const errors = useErrors({
    onUnknownErrorKey(key, errors) {
      errors.forEach((error) => toast.error(error));
      captureMessage("[appointment-modal]: unknown validation attribute", {
        level: "warning",
        tags: { booking: "validate" },
        extra: {
          key,
          errors,
        },
      });
    },
  });
  const flag = useFlag();
  const enableConsent = flag({ id: "appointment.consent", description: "" });

  const availableInsurances = insurances.filter((item) =>
    doctorServicesList.some((service) => service.insurances.includes(item.id)),
  );

  const fields = useAppointmentFormFields({
    branch_services,
    initialSlot,
    errors,
    enableConsent,
  });

  const otpFields = useAppointmentOtpFields(errors);

  const selectedService = fields.service.value
    ? doctorServicesList.find(
        (service) => service.service === fields.service.value,
      )
    : undefined;

  const { additionalFields, appointment_fields } = useAdditionalFields({
    errors,
    branch_services,
    selectedService,
  });
  const insuranceFields = useAppointmentInsuranceFields({
    insurances: availableInsurances,
  });

  const [step, setStep] = useState<"info" | "slots" | "form" | "otp">(
    selectedService?.on_walk_in_basis ||
      Boolean(selectedService?.price_conditions)
      ? "info"
      : initialSlot
        ? "form"
        : "slots",
  );

  const { mutateAsync: mutateValidate, isPending: isValidationPending } =
    useV1AppointmentValidate();

  const { mutateAsync: mutateCreate, isPending: isCreatePending } =
    useV1AppointmentCreate();

  const enableOtp = flag({
    id: "b25a834b-b76d-4f54-9a10-29aecad190cd",
    description: "otp-enable",
  });

  const { isPending: isOtpPending, sendOtp } = useSendOtp({
    errors,
    onError: () => setStep("form"),
  });

  const validateAppointment = async (
    payload: UseV1AppointmentValidateParams,
  ): Promise<boolean> => {
    const response = await mutateValidate(payload);
    if (response.ok === false) {
      handleValidationError(response.error, payload);
      return false;
    }
    return true;
  };

  const createAppointment = async (
    payload: UseV1AppointmentValidateParams & { code?: string },
  ): Promise<{ id: number; hash: string } | null> => {
    const response = await mutateCreate(payload);
    if (response.ok === false) {
      handleCreateError(response.error, payload);
      return null;
    }
    return response.data;
  };

  const handleValidationError = (
    error: GenericErrorResponse,
    payload: UseV1AppointmentValidateParams,
  ): void => {
    captureException(
      new AppointmentValidateError(error.errors[0]?.detail || "Unknown error"),
      {
        level: "error",
        tags: { booking: "validate" },
        contexts: {
          request_data: payload,
          response_data: {
            type: error.type,
            errors: error.errors.map((err) => ({
              detail: err.detail,
              code: err.code,
            })),
          },
        },
        extra: {
          messages: error.errors.map((err) => err.detail),
          full_error_response: JSON.stringify(error),
        },
      },
    );

    if (error.type === "validation_error") {
      errors.update(
        error.errors.map((err) => ({
          key: err.attr ?? "generic",
          message: err.detail,
        })),
      );
    } else {
      error.errors.map(({ detail }) => toast.error(detail));
    }
  };

  const handleCreateError = (
    error: GenericErrorResponse,
    payload: UseV1AppointmentValidateParams,
  ): void => {
    captureException(
      new AppointmentCreateError(error.errors[0]?.detail || "Unknown error"),
      {
        level: "error",
        tags: { booking: "create" },
        contexts: {
          request_data: payload,
          response_data: {
            type: error.type,
            errors: error.errors.map((err) => ({
              detail: err.detail,
              code: err.code,
            })),
          },
        },
        extra: {
          messages: error.errors.map((err) => err.detail),
          full_error_response: JSON.stringify(error),
        },
      },
    );

    if (error.type === "validation_error") {
      errors.update(
        error.errors.map((err) => ({
          key: err.attr === "code.code" ? "otp" : (err.attr ?? "generic"),
          message: err.detail,
        })),
      );
    } else {
      error.errors.map(({ detail }) => toast.error(detail));
    }
  };

  const handleFormSubmission = async (): Promise<void> => {
    if (!selectedService || !fields.slot.value) return;

    const clientInfo = getGAClientInfo();
    const payload: UseV1AppointmentValidateParams = {
      client_name:
        fields.name.value +
        (fields.surname.value ? " " + fields.surname.value : ""),
      client_phone: fields.phone.value,
      doctor_service: selectedService.id,
      slot: fields.slot.value.slot.id,
      source: isMobile ? "web-mobile" : "desktop",
      booking_page: request_meta?.booking_page,
      page_location: document.location.toString(),
      page_referrer: document.referrer,
      list_position: request_meta?.list_position,
      additional_fields: Object.entries(additionalFields)
        .map(([key, field]) => {
          if (typeof field === "function") return null;
          return {
            clinic_branch_field: parseInt(key),
            value: String(field.value),
          };
        })
        .filter(truthy),
      ...(insuranceFields.checkbox.value && {
        insurance: insuranceFields.select.value?.id,
      }),
      ...clientInfo,
    };

    if (step === "form" && !(await validateAppointment(payload))) {
      return;
    }

    if (enableOtp && step === "form") {
      const otpResponse = await sendOtp(fields.phone.value);
      if (otpResponse) {
        setStep("otp");
        return;
      }
    }

    const appointment = await createAppointment({
      ...payload,
      ...(otpFields.code.value ? { code: otpFields.code.value } : {}),
    });

    if (!appointment) return;

    sendGTagEvent({ event: "submit-appointment-form-success" });
    sendGTagEvent({
      event: "begin_checkout",
      currency: country.currency,
      shipping: 0,
      tax: 0,
      value: selectedService.total || 0,
    });

    navigate(
      routePath({
        path: "/appointments/:appointment_id",
        params: { appointment_id: appointment.id.toString() },
        query: { hash: appointment.hash },
      }),
    );
  };

  const ageBlock = (
    <PatientAgeBlock
      doctor_services={doctorServicesList}
      css={{
        marginBlockEnd: 24,
      }}
    />
  );

  const title = message({
    id: "appointment-modal.title",
    default: "Запись на прием",
  });

  if (step === "otp") {
    return (
      <OtpStep
        fields={otpFields}
        onSubmit={handleFormSubmission}
        phone={fields.phone.value}
        onChangeStep={setStep}
        errors={errors}
        isPending={isCreatePending}
      />
    );
  }

  return (
    <Modal.Content
      onBackButtonClick={step === "slots" ? () => setStep("form") : undefined}
      disableCloseButton={step === "slots"}
      title={title}
      main={
        <ModalBodyContainer>
          <ModalHeader
            doctor={doctor}
            city={city}
            cityArea={cityArea}
            branch_services={branch_services}
            styles={css({ marginBlockEnd: 20 })}
          />
          {step === "info" && (
            <>
              {ageBlock}
              <AppointmentInfoBlock
                liveQueue={selectedService?.on_walk_in_basis}
                avgWaitingTime={selectedService?.avg_waiting_time}
                conditions={selectedService?.price_conditions}
              />
            </>
          )}
          {step === "form" && (
            <>
              {ageBlock}
              {selectedService && (
                <ModalPrice
                  total={selectedService.total}
                  discount={selectedService.discount}
                  price={selectedService.price}
                  styles={css({ marginBlockEnd: 20 })}
                  service_type={selectedService.service_type}
                  insurances={selectedService.insurances}
                />
              )}
              <Form
                fields={
                  [fields, additionalFields] as Array<
                    FormFields<{
                      [x: string]: Field<any>;
                    }>
                  >
                }
                onSubmit={handleFormSubmission}
              >
                <AppointmentForm
                  fields={fields}
                  enableConsent={enableConsent}
                  appointment_fields={appointment_fields}
                  additionalFields={additionalFields}
                  doctor_services={doctorServicesList}
                  branchSlots={branchSlots}
                  selectedService={selectedService}
                  onChangeSlotPress={() => setStep("slots")}
                  insurances={availableInsurances}
                  insuranceFields={insuranceFields}
                />
              </Form>
            </>
          )}
          {step === "slots" && selectedService && (
            <SlotsBlock
              doctor={doctor}
              branchSlots={branchSlots}
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              selectedService={selectedService}
              onSlotChange={(slot) => {
                fields.slot.onChange({
                  ...fields.slot,
                  value: slot,
                });
                setStep("form");
              }}
            />
          )}
        </ModalBodyContainer>
      }
      footer={
        <ModalBodyContainer>
          <div
            css={{
              marginBlockEnd: isMobile ? 30 : 20,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            {step === "info" && (
              <ButtonV2
                minWidth={230}
                width={isMobile ? "100%" : undefined}
                variant="solid"
                text={message({
                  id: "8d3ad07a-149b-4e43-887a-e780fd97f584",
                  default: "Продолжить",
                })}
                onPress={() => setStep(initialSlot ? "form" : "slots")}
              />
            )}
            {step === "form" && (
              <ButtonV2
                minWidth={230}
                width={isMobile ? "100%" : undefined}
                variant="solid"
                text={message({
                  id: "appointment-modal.submit",
                  default: "Записаться",
                })}
                disabled={
                  isValidationPending || isCreatePending || isOtpPending
                }
                id="bookingBtn"
                onPress={() => {
                  sendGTagEvent({
                    event: "submit-appointment-form",
                  });
                  if (
                    [fields.validate(), additionalFields.validate()].every(
                      (res) => res,
                    )
                  ) {
                    handleFormSubmission();
                  }
                }}
              />
            )}
            {step === "form" && <AppointmentLegalsBlock />}
          </div>
        </ModalBodyContainer>
      }
    />
  );
};
