import {
  type InfiniteData,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { PaginatedResponse } from "../common";
import type { Service } from "./definitions";

type UseV1ServicesParams = {
  search?: string;
  limit?: number | string;
  offset?: number;
  id?: number[];
  city?: number;
  city_area?: number;
  doctor?: number;
  clinic_branch?: number;
  clinic?: number;
  has_adult_service?: boolean;
  is_primary?: boolean;
  type?: "initial-appointment" | "procedure";
  expand?: "min_price"[];
  category?: number;
  language?: string;
};
export type UseV1ServicesResult = PaginatedResponse<Service[]>;

type UseV1ServicesOptions = UseQueryOptions<UseV1ServicesResult>;

export const useV1Services = (
  { language, ...params }: UseV1ServicesParams,
  options?: Partial<UseV1ServicesOptions>,
) => {
  const url = `/api/v1/services/`;
  const { cleanApi } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params, language],
    queryFn: () => {
      return cleanApi<UseV1ServicesResult>(
        {
          url,
          method: "GET",
          headers: language
            ? {
                "Accept-Language": language,
              }
            : undefined,
          params: {
            ...params,
            id: params.id?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1ServicesListParams = UseV1ServicesParams;
export type UseV1ServicesListResult = PaginatedResponse<Service[]>;

type UseV1ServicesListOptions = UseInfiniteQueryOptions<
  UseV1ServicesListResult,
  Error,
  InfiniteData<UseV1ServicesListResult>
>;

export const useV1ServicesList = (
  { language, ...params }: UseV1ServicesListParams,
  options?: Partial<UseV1ServicesListOptions>,
) => {
  const url = `/api/v1/services/`;
  const { cleanApi } = useApi();

  return useInfiniteQuery({
    ...options,
    queryKey: [url, params, language],
    queryFn: ({ pageParam }) => {
      return cleanApi<UseV1ServicesResult>(
        {
          url,
          method: "GET",
          headers: language
            ? {
                "Accept-Language": language,
              }
            : undefined,
          params: {
            ...params,
            offset: params.offset ?? pageParam,
            id: params.id?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
    initialPageParam: params.offset,
    getNextPageParam({ next }) {
      if (!next) return undefined;
      const url = new URL(next);

      return url.searchParams.get("offset");
    },
  });
};

type UseV1ServicesSuspenseParams = UseV1ServicesParams;
export type UseV1ServicesSuspenseResult = UseV1ServicesResult;

type UseV1ServicesSuspenseOptions =
  UseSuspenseQueryOptions<UseV1ServicesSuspenseResult>;

export const useV1ServicesSuspense = (
  { language, ...params }: UseV1ServicesSuspenseParams,
  options?: Partial<UseV1ServicesSuspenseOptions>,
) => {
  const url = `/api/v1/services/`;
  const { cleanApi } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params, language],
    queryFn: () => {
      if (params.id?.length === 0)
        return { next: null, previous: null, count: 0, results: [] };

      return cleanApi<UseV1ServicesSuspenseResult>(
        {
          url,
          method: "GET",
          headers: language
            ? {
                "Accept-Language": language,
              }
            : undefined,
          params: {
            ...params,
            id: params.id?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};
