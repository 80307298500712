import type { DoctorService } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import { cssFns, type CSSProp, usePrincipalColors } from "@hermes/ui";

import {
  DesktopPopupCancelIcon,
  DesktopPopupDoneIcon,
} from "#internal/shared/ui/icons";

import { isChildService } from "./child";

const AgeInfoRow = ({ text, active }: { text: string; active?: boolean }) => {
  const principalColors = usePrincipalColors();

  return (
    <>
      {active ? (
        <DesktopPopupDoneIcon color={principalColors.ebblue} />
      ) : (
        <DesktopPopupCancelIcon color={principalColors.gs8} />
      )}
      <span css={{ color: active ? "inherit" : principalColors.gs8 }}>
        {text}
      </span>
    </>
  );
};

export const PatientAgeBlock = ({
  doctor_services,
  css,
}: {
  doctor_services: DoctorService[];
  css?: CSSProp;
}) => {
  const principalColors = usePrincipalColors();
  const message = useMessage();

  const hasAdult = doctor_services.some((service) => !isChildService(service));
  const childService = doctor_services.find((service) =>
    isChildService(service),
  );

  const age_from = childService?.age_from || 0;
  const age_to = childService?.age_to || 216;

  const ageFrom = childService
    ? Math.floor(age_from > 12 ? age_from / 12 : age_from)
    : undefined;
  const ageTo = childService
    ? Math.floor(age_to > 12 ? age_to / 12 : age_to)
    : undefined;

  return (
    <div
      css={[
        css,
        cssFns.border({ radius: 12 }),
        cssFns.padding(10),
        cssFns.typo({ level: "body-1", weight: "regular" }),
        {
          backgroundColor: principalColors.gs12,
          color: principalColors.gs2,
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          columnGap: 10,
          rowGap: 6,
          marginBlockEnd: 24,
        },
      ]}
    >
      <AgeInfoRow
        text={message({
          id: "c809664b-9d7a-4570-9fd6-054f9cef25aa",
          default: "Взрослые пациенты",
        })}
        active={hasAdult}
      />
      <AgeInfoRow
        text={message({
          id: "common.age-info",
          default:
            "Дети (от {age_from} {age_from_unit, select, m {мес.} other {лет}} до {age_to} {age_to_unit, select, m {мес.} other {лет}})",
          values:
            ageFrom && ageTo && childService
              ? {
                  age_from: ageFrom,
                  age_to: ageTo,
                  age_from_unit: age_from > 12 ? "y" : "m",
                  age_to_unit: age_from > 12 ? "y" : "m",
                }
              : {
                  age_from: 0,
                  age_to: 18,
                  age_from_unit: "m",
                  age_to_unit: "y",
                },
        })}
        active={!!childService}
      />
    </div>
  );
};
