import {
  type InfiniteData,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useQueries,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { PaginatedResponse } from "../common";
import type { ClinicBranch } from "./definitions";

type UseV1ClinicBranchesListParams = {
  limit?: number;
  offset?: number;
  id?: number[];
  city?: number;
  city_area?: number;
  clinic?: number;
  search?: string;
  expand?: (
    | "slug"
    | "closest_stations"
    | "schedule"
    | "schedule_settings"
    | "city"
    | "city_area"
  )[];
};
export type UseV1ClinicBranchesListResult = PaginatedResponse<ClinicBranch[]>;

type UseV1ClinicBranchesListOptions = UseQueryOptions<
  UseV1ClinicBranchesListResult,
  Error,
  UseV1ClinicBranchesListResult
>;

export const useV1ClinicBranchesList = (
  params: UseV1ClinicBranchesListParams,
  options?: Partial<UseV1ClinicBranchesListOptions>,
) => {
  const url = `/api/v1/clinic-branches/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV1ClinicBranchesListResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            id: params.id?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1ClinicBranchesListInfiniteOptions = UseInfiniteQueryOptions<
  UseV1ClinicBranchesListResult,
  Error,
  InfiniteData<UseV1ClinicBranchesListResult>
>;

export const useV1ClinicBranchesListInfinite = (
  params: UseV1ClinicBranchesListParams,
  options?: Partial<UseV1ClinicBranchesListInfiniteOptions>,
) => {
  const url = `/api/v1/clinic-branches/`;
  const { api } = useApi();

  return useInfiniteQuery({
    ...options,
    queryKey: [url, params],
    queryFn: ({ pageParam }) => {
      return api<UseV1ClinicBranchesListResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            offset: params.offset ?? pageParam,
            id: params.id?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
    initialPageParam: params.offset,
    getNextPageParam({ next }) {
      if (!next) return undefined;
      const url = new URL(next);

      return url.searchParams.get("offset");
    },
  });
};

type UseV1ClinicBranchesListParallelParams = {
  groupedIds: number[][];
  expand?: (
    | "clinic_slug"
    | "closest_stations"
    | "schedule"
    | "schedule_settings"
    | "city_area"
    | "city"
  )[];
  lat?: number;
  lng?: number;
};
export type UseV1ClinicBranchesListParallelResult = PaginatedResponse<
  ClinicBranch[]
>;
type UseV1ClinicBranchesListParallelOptions =
  UseQueryOptions<UseV1ClinicBranchesListParallelResult>;

export const useV1ClinicBranchesListParallel = (
  { groupedIds, expand, ...params }: UseV1ClinicBranchesListParallelParams,
  options?: Partial<UseV1ClinicBranchesListParallelOptions>,
) => {
  const url = `/api/v1/clinic-branches/`;
  const { api } = useApi();

  return useQueries({
    queries: groupedIds.map((ids) => {
      return {
        ...options,
        enabled:
          options?.enabled !== undefined
            ? options.enabled && ids.length > 0
            : ids.length > 0,
        queryKey: [url, ids, expand, params],
        queryFn: () => {
          return api<UseV1ClinicBranchesListParallelResult>(
            {
              url,
              method: "GET",
              params: {
                ...params,
                limit: ids.length,
                id: ids.join(","),
                expand: expand?.join(","),
              },
            },
            {
              200: async (r) => r.data,
            },
          );
        },
      };
    }),
  });
};
