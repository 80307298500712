import type { City, DoctorService } from "@hermes/api";
import { availableCountries, resolveCountryInfo } from "@hermes/shared/schemas";
import useLocalStorage from "@rehooks/local-storage";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import { useEffect } from "react";

export const getCityById = (cities: City[], cityId?: number) => {
  if (!cityId) return;
  return cities.find((city) => city.id === cityId);
};

export const sortServicesByName = (services?: DoctorService[]) => {
  if (!services) return;

  return services.sort((a, b) => {
    const nameA = a.service_name?.toLowerCase() ?? "";
    const nameB = b.service_name?.toLowerCase() ?? "";

    // Сортировка по service_name
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0; // Если имена одинаковы
  });
};

export const countryInfo = resolveCountryInfo(
  availableCountries.parse(import.meta.env.DOQ_COUNTRY_ID),
);

export const useStoredLocale = () => {
  return useLocalStorage<string>("locale", countryInfo.defaultLocale);
};

declare global {
  interface Window {
    prerenderReady?: boolean;
  }
}

export function usePrerenderReady(delay = 200) {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const checkReady = () => {
      if (isFetching === 0 && isMutating === 0) {
        // Small delay to ensure Helmet and Emotion finish
        timeout = setTimeout(() => {
          window.prerenderReady = true;
        }, delay);
      } else {
        // Still loading – reset if needed
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
      }
    };

    checkReady(); // initial check

    const interval = setInterval(checkReady, 50); // re-check every 50ms

    return () => {
      clearInterval(interval);
      if (timeout) clearTimeout(timeout);
    };
  }, [isFetching, isMutating, delay]);
}
