import { css } from "@emotion/react";
import { type ReactNode, useEffect, useRef, useState } from "react";

export type DropdownProps = {
  children:
    | ReactNode
    | ((props: {
        open: boolean;
        toggleDropdown: (forceState?: boolean) => void;
      }) => ReactNode);
  isOpen?: boolean;
  onToggle?: (open: boolean) => void;
  className?: string;
};

export const DropdownBody = ({
  children,
  isOpen,
  onToggle,
  className,
}: DropdownProps) => {
  const [open, setOpen] = useState(isOpen || false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = (forceState?: boolean) => {
    const newState = forceState ?? !open;
    setOpen(newState);
    onToggle?.(newState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
        onToggle?.(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onToggle]);

  return (
    <div ref={dropdownRef} css={dropdownStyles} className={className}>
      {typeof children === "function"
        ? children({ open, toggleDropdown })
        : children}
    </div>
  );
};

const dropdownStyles = css`
  position: relative;
  display: inline-block;
`;
