import {
  ButtonV2,
  cssFns,
  icons,
  useDebouncedValue,
  useOverlayContainer,
  usePrincipalColors,
} from "@hermes/ui";
import { type ReactNode, useEffect, useRef, useState } from "react";
import {
  FocusScope,
  Overlay,
  useDialog,
  useId,
  useModalOverlay,
} from "react-aria";

type SideMenuProps = {
  isDismissible?: boolean;
  state: SideMenuState;
  onClose?: () => void;
  content: {
    logo: ReactNode;
    main: ReactNode;
    footer?: ReactNode;
  };
};

export function SideMenu({
  isDismissible = true,
  state,
  onClose,
  content: { logo, main, footer },
}: SideMenuProps) {
  const { containerRef, modalStack, onModalStackChange } =
    useOverlayContainer();
  const principalColors = usePrincipalColors();

  const [identity] = useState({
    id: useId(),
  });

  useEffect(() => {
    onModalStackChange((stack) => [...stack, identity]);

    return () => {
      onModalStackChange((stack) => stack.filter((item) => item !== identity));
    };
  }, [identity, onModalStackChange]);

  const isLast = modalStack[modalStack.length - 1] === identity;

  const innerModalRef = useRef<HTMLDivElement>(null);
  const modalRef = innerModalRef;
  const { modalProps, underlayProps } = useModalOverlay(
    { isDismissable: isDismissible },
    { ...state, close: onClose ? onClose : state.close },
    modalRef,
  );

  const { dialogProps } = useDialog({}, modalRef);

  return (
    <Overlay
      portalContainer={containerRef.current ?? undefined}
      disableFocusManagement
      isExiting={state.isUnmounting}
    >
      <FocusScope contain={isLast} restoreFocus>
        <div
          css={[
            cssFns.inset(0),
            {
              position: "fixed",
              zIndex: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            },
            {
              animation: state.isUnmounting
                ? "fade-out 0.2s ease-in-out"
                : "fade-in 0.2s ease-in-out",
            },
          ]}
          {...underlayProps}
        >
          <div
            aria-hidden="true"
            css={{
              zIndex: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
          <div
            {...modalProps}
            ref={modalRef}
            css={[
              {
                animation: state.isUnmounting
                  ? `slide-out-left 0.3s ease-in-out`
                  : `slide-in-left 0.4s ease-in-out`,
              },
              {
                "@keyframes slide-in-left": {
                  from: { transform: "translateX(-100%)" },
                  to: { transform: "translateX(0%)" },
                },
                "@keyframes slide-out-left": {
                  from: { transform: "translateX(0%)" },
                  to: { transform: "translateX(-100%)" },
                },
                position: "fixed",
                top: 0,
                left: 0,
                width: "90%",
                height: "100%",
                backgroundColor: principalColors.white,
                boxShadow: "2px 0 5px rgba(0,0,0,0.3)",
                zIndex: 100,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              },
            ]}
          >
            <div
              {...dialogProps}
              css={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div
                css={[
                  {
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    paddingInlineStart: 10,
                  },
                ]}
              >
                {logo}
                <div css={[cssFns.padding(14)]}>
                  <ButtonV2
                    text="modal close button"
                    variant="icon"
                    onPress={onClose ?? state.close}
                    icon={<icons.LineClose color={principalColors.gs2} />}
                  />
                </div>
              </div>

              <div
                css={[
                  cssFns.padding(30, 10),
                  {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflow: "auto",
                  },
                ]}
              >
                <div css={{ flexGrow: 1, overflow: "auto" }}>{main}</div>

                {footer && (
                  <div css={[{ flexShrink: 0, marginBlockStart: 30 }]}>
                    {footer}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </FocusScope>
    </Overlay>
  );
}

export type SideMenuState = ReturnType<typeof useSideMenuState>;

export function useSideMenuState<T>({
  defaultValue,
  delayed = true,
  parentState,
}: {
  defaultValue?: T;
  parentState?: {
    open: () => void;
    close: () => void;
    toggle: () => void;
    setOpen: (value: boolean) => void;
    isOpen: boolean;
  };
  delayed?: boolean;
}) {
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(parentState?.isOpen ?? false);
  const delayedIsOpen = useDebouncedValue(isOpen, 150);

  const isUnmounting = !isOpen && delayedIsOpen;

  const open = (value?: T) => {
    setIsOpen(true);
    setValue(value);
    parentState?.open();
  };
  const close = () => {
    setIsOpen(false);
    setValue(undefined);
    parentState?.close();
  };
  const toggle = () => {
    isOpen ? close() : open();
    parentState?.toggle();
  };

  useEffect(() => {
    if (typeof parentState === "undefined") return;
    if (isOpen !== parentState.isOpen) {
      setIsOpen(parentState.isOpen);
    }
  }, [parentState?.isOpen, isOpen]);

  return {
    value,
    isUnmounting,
    isOpen: isOpen ? isOpen : delayed ? delayedIsOpen : isOpen,
    open,
    close,
    setOpen: setIsOpen,
    toggle,
  };
}
