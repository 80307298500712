import type { Insurance } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import {
  Checkbox,
  type CheckboxState,
  cssFns,
  type Field,
  type FormFields,
  Select,
  type SelectOption,
  type SelectState,
  usePrincipalColors,
} from "@hermes/ui";

export const InsuranceField = ({
  fields: { select, checkbox },
  options,
}: {
  fields: FormFields<{
    select: Field<SelectState<Insurance>>;
    checkbox: Field<CheckboxState>;
  }>;
  options: SelectOption<Insurance>[];
}) => {
  const message = useMessage();

  const principalColors = usePrincipalColors();

  return (
    <div>
      <Checkbox
        field={checkbox}
        label={message({
          id: "182b9c2b-84ce-49ba-a174-9f137ee55d77",
          default: "Am bilet de trimitere (decontare CNAS)",
        })}
      />
      {checkbox.value && (
        <div
          css={[
            cssFns.padding(10),
            {
              background: cssFns.setOpacity(principalColors.ebblue, 0.1),
              marginBlockStart: 1,
            },
          ]}
        >
          <Select
            field={select}
            variant="desktop"
            options={options}
            disableLabelFocus
          />
        </div>
      )}
    </div>
  );
};
