import { useLocale, useMessage } from "@hermes/intl";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useMatches, useParams } from "react-router-dom";

import { useAppConfig } from "./app/config/context";
import { useSendGTagEvent } from "./app/gtag";
import { RoutesIdEnum } from "./app/router";
import { pageVisitExceptionsRoutes } from "./app/router/router";

export const AppHelmet = ({
  title,
  description,
  keywords,
  ogImageUrl,
  isNoIndex,
  isNoIndexNoFollow,
  isDoctorsAggregateRating,
  feedbackScore,
  feedbackCount,
  maxPrice,
  minPrice,
  currency,
}: {
  title?: string;
  description?: string;
  keywords?: string;
  ogImageUrl?: string;
  isNoIndex?: boolean;
  /* isNoIndex:
    near me,
    onMap page,
    Doctors: filters.sortBy !== "default" || noIndexCityAreas ||  filters.forChildrenAge || filters.date?.slot_date || filters?.insurances?.length > 0;
    Survey
    Register Clinic
    Agreements
    Login Page
    User Profile
    Clinic cabinets
  */
  isNoIndexNoFollow?: boolean;
  isDoctorsAggregateRating?: boolean;
  feedbackScore?: number;
  feedbackCount?: number;
  maxPrice?: number | null;
  minPrice?: number | null;
  currency?: string;
}) => {
  const [locale] = useLocale();
  const location = useLocation();
  const matches = useMatches();
  const outletRoute = matches[matches.length - 1];

  const params = useParams();
  const { availableCountriesInfo, country } = useAppConfig();
  const message = useMessage();
  const { sendGTagEvent } = useSendGTagEvent();

  const defaultTitle = message({
    id: "title",
    default: "{brand} - сервис по поиску врачей и записи на прием",
    values: { brand: country.brandName },
  });

  const pageViewTitle =
    outletRoute?.id === RoutesIdEnum.HOME ? (title ?? defaultTitle) : title;

  useEffect(() => {
    if (
      outletRoute?.pathname &&
      pageViewTitle &&
      !pageVisitExceptionsRoutes.includes(outletRoute.id as RoutesIdEnum)
    ) {
      sendGTagEvent({
        event: "page_view",
        page_location: document.location.toString(),
        page_title: pageViewTitle,
        allowDuplicate: false,
      });
    }
  }, [outletRoute?.pathname, outletRoute?.id, pageViewTitle]);

  const basePath = `https://${window.location.hostname}`;
  const pathname =
    location.pathname === "/"
      ? `${basePath}`
      : `${basePath}${location.pathname}`;

  const isHomePage = location.pathname === "/";
  const isNotFoundPage = location.pathname.startsWith("/404");
  const isDefaultLocale =
    params["lang"] && params["lang"] !== country.defaultLocale;

  const regionAlternateLinks = availableCountriesInfo
    .filter((ac) => ac.countryId === country.countryId)
    .map((ac) => (
      <link
        key={ac.countryId}
        rel="alternate"
        hrefLang={`${country.defaultLocale}-${ac.region}`}
        href={basePath}
      />
    ));
  const alternateWebsiteLinks = availableCountriesInfo
    .filter((c) => c.countryId !== country.countryId)
    .map((country) => (
      <link
        key={country.countryId}
        rel="alternate"
        hrefLang={country.lang}
        href={country.link}
      />
    ));

  /* For micro-markup */
  const doctorsPrice =
    maxPrice && (minPrice || minPrice === 0) && currency
      ? message({
          id: "271397c0-ddb7-4d7d-b5f0-ed1370ff6c69",
          default: "Прием от {min} до {max} {currency}",
          values: {
            min: minPrice,
            max: maxPrice,
            currency,
          },
        })
      : "";

  useEffect(() => {
    // TODO: for some reason, synchronous console.log call gives helmet
    // just enough time to not mess up the data binding during the unmount/mount cycle
    if (import.meta.env.DOQ_ENVIRONMENT === "production") {
      // eslint-disable-next-line no-console
      console.info("Synchronous Helmet mount");
    }
  }, []);

  return (
    <Helmet prioritizeSeoTags defer={false}>
      <html lang={locale.baseName} />
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {/* Open Graph meta tags */}
      <meta property="og:locale" content={country.ogLocale} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={pathname} />
      <meta
        property="og:image"
        content={ogImageUrl ?? `${basePath}/logo_blue.svg`}
      />
      {/* No index */}
      {(isNoIndex || params["lang"]) && ( //|| onMapPage, /nearme
        <meta name="robots" content="noindex" />
      )}
      {(isNoIndexNoFollow || isDefaultLocale) && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {isNotFoundPage && <meta name="prerender-status-code" content="404" />}
      {/* Alternative Links */}
      {isHomePage && regionAlternateLinks}
      {isHomePage && alternateWebsiteLinks}
      {/* Canonical */}
      <link rel="canonical" href={pathname} />
      {/* Organization and Logotype Micro-markup */}
      <script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            url: basePath,
            logo: basePath + "/logo.svg",
            address: country.address,
          },
          null,
          2,
        )}
      </script>
      {/* Doctors Price Micro-markup */}
      {isDoctorsAggregateRating && feedbackCount && feedbackCount > 0 && (
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "http://schema.org",
              "@type": "Review",
              itemReviewed: {
                "@type": "LocalBusiness",
                name: title,
                url: pathname,
                ...(doctorsPrice ? { priceRange: doctorsPrice } : {}),
              },
              author: {
                "@type": "Organization",
                name: country.brandName,
                url: country.link,
              },
              reviewRating: {
                "@type": "AggregateRating",
                ratingValue: Number((feedbackScore ?? 0).toFixed(1)),
                worstRating: 0,
                bestRating: 10,
                reviewCount: feedbackCount,
              },
            },
            null,
            2,
          )}
        </script>
      )}
    </Helmet>
  );
};
