import { css } from "@emotion/react";
import { ButtonV2, cssFns, icons } from "@hermes/ui";
import { useState } from "react";

type AccordionProps = {
  title: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
};

export const Accordion = ({
  title,
  children,
  defaultOpen = false,
}: AccordionProps) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <div css={cssFns.marginBlock(12)}>
      <ButtonV2
        width={"100%"}
        variant="accordion"
        text={title}
        styles={css({ justifyContent: "space-between", padding: 0 })}
        onPress={() => setOpen(!open)}
        icon={open ? <icons.DesktopArrowUp /> : <icons.DesktopArrowDown />}
      />
      <div
        css={{
          ...(!open && { display: "none" }),
        }}
      >
        {children}
      </div>
    </div>
  );
};
