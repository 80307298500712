import { css } from "@emotion/react";
import {
  createContext,
  type ReactNode,
  type RefObject,
  type SetStateAction,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

const Context = createContext<{
  containerRef: RefObject<HTMLElement>;
  modalStack: unknown[];
  onModalStackChange: (value: SetStateAction<unknown[]>) => void;
} | null>(null);

export const useOverlayContainer = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      "useOverlayContainer must be used inside OverlayContainerProvider",
    );
  }

  return context;
};

function setVh() {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`,
  );
}
window.addEventListener("resize", setVh);
setVh();

const bodyStyles = css({
  overflow: "hidden",
  position: "fixed",
  top: "0px",
  left: 0,
  right: 0,
  bottom: 0,
});

export const OverlayContainerProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [stack, setStack] = useState<unknown[]>([]);
  const containerRef = useRef<HTMLElement>(document.body);

  useLayoutEffect(() => {
    if (stack.length) {
      const { top } = containerRef.current.getBoundingClientRect();
      containerRef.current.style.cssText = bodyStyles.styles;
      containerRef.current.style.top = top + "px";
    } else {
      const top =
        parseInt(containerRef.current.style.top.replace("px", "")) || 0;
      containerRef.current.style.cssText = "";
      if (top) {
        requestAnimationFrame(() => {
          document.documentElement.scrollTop = Math.abs(top);
        });
      }
    }
  }, [containerRef.current, stack]);

  return (
    <Context.Provider
      value={{
        containerRef,
        modalStack: stack,
        onModalStackChange: setStack,
      }}
    >
      <div
        {...(stack.length > 0
          ? {
              inert: "true",
              "aria-hidden": "true",
            }
          : {})}
      >
        {children}
      </div>
    </Context.Provider>
  );
};
