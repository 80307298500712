import { Flag } from "@hermes/flags";
import { lazy } from "@hermes/ui";
import { wrapCreateBrowserRouter } from "@sentry/react";
import {
  createBrowserRouter,
  Navigate,
  type RouteObject,
} from "react-router-dom";

import { DoctorsListPage } from "#internal/pages/doctors-list/page";

import { AppContainer } from "../app-container";
import { RoutesIdEnum } from "./types";

const { NotFoundPage } = lazy(() => import("#internal/pages/not-found/page"));
const { HomePage } = lazy(() => import("#internal/pages/home/page"));
// const { DoctorsListPage } = lazy(
//   () => import("#internal/pages/doctors-list/page"),
// );
const { DoctorProfilePage } = lazy(
  () => import("#internal/pages/doctor-profile"),
);
const { ClinicsPage } = lazy(() => import("#internal/pages/clinics"));
const { ClinicProfilePage } = lazy(
  () => import("#internal/pages/clinic-profile"),
);
const { AppointmentPage } = lazy(() => import("#internal/pages/appointment"));
const { ProceduresListPage } = lazy(() => import("#internal/pages/procedures"));
const { FeedbackPage } = lazy(() => import("#internal/pages/feedback"));

const { ProceduresByCategory } = lazy(
  () => import("#internal/pages/procedures"),
);
const { MapPage } = lazy(() => import("#internal/pages/map/page"));
const { ContactsPage } = lazy(() => import("#internal/pages/contacts"));
const { JoinClinic } = lazy(() => import("#internal/pages/join-clinic"));

const { Impresszum } = lazy(() => import("#internal/pages/legal/impresszum"));
const { PrivacyPolicy } = lazy(
  () => import("#internal/pages/legal/privacy_policy"),
);
const { UserGTC } = lazy(() => import("#internal/pages/legal/user_gtc"));
const { PartnerGTC } = lazy(() => import("#internal/pages/legal/partner_gtc"));

const { ServicesRedirectPage } = lazy(
  () => import("#internal/pages/services-redirect"),
);

const { ProcessingAgreement } = lazy(
  () => import("#internal/pages/legal/processing_agreement"),
);
const { SurveyPage } = lazy(() => import("#internal/pages/survey"));

const routes = [
  {
    path: "/:lang?",
    element: <AppContainer />,
    children: [
      {
        index: true,
        element: (
          <Flag
            id="e87753e2-a203-433f-8897-55257c104f1d"
            description="Enable home page route"
            fallback={<NotFoundPage />}
          >
            <HomePage />
          </Flag>
        ),
        id: RoutesIdEnum.HOME,
      },
      {
        path: "doctors/:city/*",
        element: (
          <Flag
            id="ad2fe9e4-05a4-424b-9502-4d8d9aa7c6e5"
            description="Enable doctors list page"
            fallback={<NotFoundPage />}
          >
            <DoctorsListPage />
          </Flag>
        ),
        id: RoutesIdEnum.DOCTORS_LIST,
      },
      {
        path: "doctor/:slug",
        element: (
          <Flag
            id="e875ec8f-4de9-4179-ae86-7621dffc876b"
            description="Enable doctor-details page route"
            fallback={<NotFoundPage />}
          >
            <DoctorProfilePage />
          </Flag>
        ),
        id: RoutesIdEnum.DOCTOR_PROFILE,
      },
      {
        path: "clinics/:city",
        element: (
          <Flag
            id="f7de8dcf-2c10-4017-b8e2-5eab52c93c8d"
            description="Enable clinics page route"
            fallback={<NotFoundPage />}
          >
            <ClinicsPage />
          </Flag>
        ),
        id: RoutesIdEnum.CLINICS_LIST,
      },
      {
        path: "procedures/:city",
        element: (
          <Flag
            id="dba053e6-5061-42d2-bc8d-c0055891eb9d"
            description="Enable procedures list page route"
          >
            <ProceduresListPage />
          </Flag>
        ),
        children: [
          {
            path: "categories",
            element: (
              <Flag
                id="f4f5c635-00da-556a-8ca6-ba47de27467b"
                description="Enable All procedures page"
              >
                <ProceduresByCategory />
              </Flag>
            ),
            id: RoutesIdEnum.PROCEDURES_BY_CATEGORIES,
          },
        ],
        id: RoutesIdEnum.PROCEDURES_LIST,
      },
      {
        path: "clinics/:city/:name",
        element: (
          <Flag
            id="93558975-dfc5-413e-b514-5c4b2f7f8bfa"
            description="Enable clinic profile page route"
            fallback={<NotFoundPage />}
          >
            <ClinicProfilePage />
          </Flag>
        ),
        id: RoutesIdEnum.CLINIC_PROFILE,
      },
      {
        path: "appointments/:appointment_id",
        element: (
          <Flag
            id="58c494f3-b6d1-4f64-bcab-6b213859fd94"
            description="Enable appointment page"
          >
            <AppointmentPage />
          </Flag>
        ),
        id: RoutesIdEnum.APPOINTMENT_PAGE,
      },
      {
        path: "feedback/:appointment_id",
        element: (
          <Flag
            id="f27904f1-2832-45f8-b12c-0660dab35258"
            description="Enable feedback page"
          >
            <FeedbackPage />
          </Flag>
        ),
        id: RoutesIdEnum.FEEDBACK_PAGE,
      },
      {
        path: "map",
        element: <MapPage />,
        id: RoutesIdEnum.MAP_PAGE,
      },
      {
        path: "contacts",
        element: <ContactsPage />,
        id: RoutesIdEnum.CONTACTS_PAGE,
      },
      {
        path: "join",
        element: <JoinClinic />,
        id: RoutesIdEnum.JOIN_CLINIC,
      },
      {
        path: "survey",
        element: <SurveyPage />,
        id: RoutesIdEnum.SURVEY,
      },
      //Service redirect page
      {
        path: "services/:city/*",
        element: (
          <Flag
            id="33e9f146-f362-4a91-bccf-a734a23dfe39"
            description="Enable services-list redirect page"
            fallback={<NotFoundPage />}
          >
            <ServicesRedirectPage />
          </Flag>
        ),
      },
      // LEGALS start
      {
        path: "impresszum",
        element: (
          <Flag id="legals.impresszum" fallback={<NotFoundPage />}>
            <Impresszum />
          </Flag>
        ),
        id: RoutesIdEnum.IMPRESSZUM,
      },
      {
        path: "privacy_policy",
        element: (
          <Flag id="legals.privacy_policy" fallback={<NotFoundPage />}>
            <PrivacyPolicy />
          </Flag>
        ),
        id: RoutesIdEnum.PRIVACY_POLICY,
      },
      {
        path: "confidentiality",
        element: <Navigate to="/privacy_policy" />,
      },
      {
        path: "user_gtc",
        element: (
          <Flag id="legals.user_gtc" fallback={<NotFoundPage />}>
            <UserGTC />
          </Flag>
        ),
        id: RoutesIdEnum.USER_GTC,
      },
      {
        path: "user-agreement",
        element: <Navigate to="/user_gtc" />,
      },
      {
        path: "processing-agreement",
        element: (
          <Flag id="legals.processing_agreement" fallback={<NotFoundPage />}>
            <ProcessingAgreement />
          </Flag>
        ),
        id: RoutesIdEnum.PROCESSING_AGREEMENT,
      },
      {
        path: "partner_gtc",
        element: (
          <Flag id="legals.partner_gtc" fallback={<NotFoundPage />}>
            <PartnerGTC />
          </Flag>
        ),
        id: RoutesIdEnum.PARTNER_GTC,
      },
      // LEGALS end
      {
        path: "404",
        element: <NotFoundPage />,
        id: RoutesIdEnum.NOT_FOUND_PAGE,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
] satisfies RouteObject[];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);

export const mobileSearchableRoutes = [
  RoutesIdEnum.DOCTORS_LIST,
  RoutesIdEnum.CLINICS_LIST,
  RoutesIdEnum.PROCEDURES_LIST,
  RoutesIdEnum.NOT_FOUND_PAGE,
];
export const cityRoutes = [
  RoutesIdEnum.DOCTORS_LIST,
  RoutesIdEnum.CLINICS_LIST,
  RoutesIdEnum.PROCEDURES_LIST,
  RoutesIdEnum.CLINIC_PROFILE,
];
export const cityAreasRoutes = [RoutesIdEnum.DOCTORS_LIST];

export const pageVisitExceptionsRoutes = [
  RoutesIdEnum.FEEDBACK_PAGE,
  RoutesIdEnum.APPOINTMENT_PAGE,
];
