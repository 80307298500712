export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T;

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

export function unique<T>(
  mapper = (value: T) => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    }
    return String(value);
  },
) {
  const lookup = new Set<string>();

  return (value: T): boolean => {
    const key = mapper(value);

    if (lookup.has(key)) {
      return false;
    }
    lookup.add(key);

    return true;
  };
}

export type SizeUnit = string | number;

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

/**
 * Requires toast container to have class .hermes-toast-container
 */
export const isToast = (element: Element) => {
  return element.closest(".hermes-toast-container") !== null;
};
