import { useSuspense } from "@hermes/ui";

export const capitalize = (str: string) => str[0]?.toUpperCase() + str.slice(1);

export type GeoPosition = {
  lat?: number;
  lng?: number;
};

const getCurrentPositionAsync = () => {
  return new Promise<
    | { status: true; data: GeoPosition }
    | { status: false; error: GeolocationPositionError }
  >((resolve) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve({
          status: true,
          data: {
            lat: coords.latitude,
            lng: coords.longitude,
          },
        });
      },
      (err) => {
        resolve({
          status: false,
          error: err,
        });
      },
    );
  });
};

export const useGeolocation = ({ enabled }: { enabled?: boolean }) => {
  const data = useSuspense(
    "position",
    () => getCurrentPositionAsync(),
    enabled,
  );

  return {
    position: data?.status === true ? data.data : undefined,
    error: data?.status === false ? data.error : undefined,
  };
};

type Primitive = string | number | symbol | undefined;

type MatchPattern<T extends Primitive> = {
  [K in Exclude<T, undefined>]?: () => any;
} & {
  undefined?: () => any;
  _?: () => any;
};

type ExtractReturnType<P> = P[keyof P] extends (...args: any) => any
  ? ReturnType<P[keyof P]>
  : never;

export function match<T extends Primitive, P extends MatchPattern<T>>(
  value: T,
  pattern: P,
): ExtractReturnType<P> {
  if (value === undefined && pattern.undefined) {
    return pattern.undefined();
  }

  if (value && value in pattern) {
    const fn = pattern[value as keyof P];
    if (typeof fn === "function") {
      return fn();
    }
  }

  if ("_" in pattern && pattern._) {
    return pattern._();
  }

  throw new Error(
    `Non-exhaustive match: no handler for value "${String(value)}"`,
  );
}

export async function hashSHA256(input: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(input.trim().toLowerCase());
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}
