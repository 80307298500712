import type { ReactNode } from "react";
import type { TextFieldAria } from "react-aria";

import { cssFns } from "../../css";
import { usePrincipalColors } from "../../theme";

export const FieldDescription = ({
  children,
  disabled,
  descriptionProps,
}: {
  children: ReactNode;
  disabled?: boolean;
  descriptionProps?: TextFieldAria["descriptionProps"];
}) => {
  const principalColors = usePrincipalColors();

  return (
    <div
      css={[
        cssFns.typo({
          level: "body-3",
          weight: "regular",
        }),
        {
          color: disabled ? principalColors.gs8 : principalColors.gs4,
          paddingInlineStart: 4,
        },
      ]}
      {...descriptionProps}
    >
      {children}
    </div>
  );
};
