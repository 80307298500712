import {
  type InfiniteData,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useQueries,
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { PaginatedResponse } from "../common";
import type { DoctorService } from "./definitions";

export type UseV1DoctorsServicesListParams = {
  limit?: number;
  offset?: number;
  id?: number[];
  service?: number[];
  doctor?: number | number[];
  clinic_branch?: number[];
  expand?: ("service_name" | "service_type" | "service_slug")[];
};
export type UseV1DoctorsServicesListResult = PaginatedResponse<DoctorService[]>;

type UseV1DoctorsServicesListOptions = UseQueryOptions<
  UseV1DoctorsServicesListResult,
  Error,
  UseV1DoctorsServicesListResult
>;

export const useV1DoctorsServicesList = (
  params: UseV1DoctorsServicesListParams,
  options?: Partial<UseV1DoctorsServicesListOptions>,
) => {
  const url = `/api/v1/doctor-services/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV1DoctorsServicesListResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            id: params.id?.join(","),
            service: params.service?.join(","),
            doctor: Array.isArray(params.doctor)
              ? params.doctor.join(",")
              : params.doctor,
            clinic_branch: params.clinic_branch?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

export type UseV1DoctorsServicesListSuspenseParams =
  UseV1DoctorsServicesListParams;
export type UseV1DoctorsServicesListSuspenseResult =
  UseV1DoctorsServicesListResult;

type UseV1DoctorsServicesListSuspenseOptions = UseSuspenseQueryOptions<
  UseV1DoctorsServicesListSuspenseResult,
  Error,
  UseV1DoctorsServicesListSuspenseResult
>;

export const useV1DoctorsServicesListSuspense = (
  params: UseV1DoctorsServicesListSuspenseParams,
  options?: Partial<UseV1DoctorsServicesListSuspenseOptions>,
) => {
  const url = `/api/v1/doctor-services/`;
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            id: params.id?.join(","),
            service: params.service?.join(","),
            doctor: Array.isArray(params.doctor)
              ? params.doctor.join(",")
              : params.doctor,
            clinic_branch: params.clinic_branch?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1DoctorsServicesListInfiniteOptions = UseInfiniteQueryOptions<
  UseV1DoctorsServicesListResult,
  Error,
  InfiniteData<UseV1DoctorsServicesListResult>
>;

export const useV1DoctorsServicesListInfinite = (
  params: UseV1DoctorsServicesListParams,
  options?: Partial<UseV1DoctorsServicesListInfiniteOptions>,
) => {
  const url = `/api/v1/doctor-services/`;
  const { api } = useApi();

  return useInfiniteQuery({
    ...options,
    queryKey: [url, params],
    queryFn: ({ pageParam }) => {
      return api<UseV1DoctorsServicesListResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            offset: params.offset ?? pageParam,
            id: params.id?.join(","),
            service: params.service?.join(","),
            doctor: Array.isArray(params.doctor)
              ? params.doctor.join(",")
              : params.doctor,
            clinic_branch: params.clinic_branch?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
    initialPageParam: params.offset,
    getNextPageParam({ next }) {
      if (!next) return undefined;
      const url = new URL(next);

      return url.searchParams.get("offset");
    },
  });
};

type UseV1DoctorsServicesListParallelParams = {
  groupedDoctorIds: number[][];
  service?: number[];
  clinic_branch?: number[];
  expand?: ("service_name" | "service_type")[];
};
export type UseV1DoctorsServicesListParallelResult = PaginatedResponse<
  DoctorService[]
>;
type UseV1DoctorsServicesListParallelOptions =
  UseQueryOptions<UseV1DoctorsServicesListParallelResult>;

export const useV1DoctorsServicesListParallel = (
  {
    groupedDoctorIds,
    service,
    clinic_branch,
    expand,
  }: UseV1DoctorsServicesListParallelParams,
  options?: Partial<UseV1DoctorsServicesListParallelOptions>,
) => {
  const url = `/api/v1/doctor-services/`;
  const { api } = useApi();

  return useQueries({
    queries: groupedDoctorIds.map((ids) => {
      return {
        ...options,
        enabled:
          options?.enabled !== undefined
            ? options.enabled && ids.length > 0
            : ids.length > 0,
        queryKey: [url, service, ids],
        queryFn: () => {
          return api<UseV1DoctorsServicesListParallelResult>(
            {
              url,
              method: "GET",
              params: {
                service: service?.join(","),
                doctor: ids.join(","),
                clinic_branch: clinic_branch?.join(","),
                expand: expand?.join(","),
              },
            },
            {
              200: async (r) => r.data,
            },
          );
        },
      };
    }),
  });
};
