import type { DoctorsListMeta, ServiceDetails } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import {
  Accordion,
  cssFns,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import Skeleton from "react-loading-skeleton";

import { DoctorsListServiceDescription } from "./service-description";

export const DoctorListFooter = ({
  isMetaLoading,
  meta,
  service,
}: {
  isMetaLoading: boolean;
  meta?: DoctorsListMeta;
  service: ServiceDetails;
}) => {
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();
  const message = useMessage();

  return (
    isMobile && (
      <div
        css={[
          cssFns.paddingInline(10),
          {
            background: principalColors.white,
            borderRadius: "12px 12px 0 0",
          },
        ]}
      >
        <Accordion
          title={message({
            id: "9af7ab40-df94-4c61-9a8b-f900df54bfc2",
            default: "О специальности",
          })}
        >
          {!isMetaLoading ? (
            meta && (
              <DoctorsListServiceDescription
                count={meta.feedback_count}
                description={meta.description}
                rating={meta.feedback_score}
                service={service}
              />
            )
          ) : (
            <Skeleton css={{ height: 20 }} />
          )}
        </Accordion>
      </div>
    )
  );
};
