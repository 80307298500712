import {
  type DoctorService,
  type ServiceDetails,
  type Slot,
} from "@hermes/api";
import { nonNullable, truthy } from "@hermes/ui";

import type { DoctorCardService } from "#internal/features/doctor-card";

import type { getSlotsDateRanges } from "./date-range";
import type { Page } from "./index";

export const mergeDoctorsPages = ({
  targetServices,
  dateRanges,
  pages,
  currentService,
}: {
  targetServices?: DoctorCardService[];
  dateRanges: ReturnType<typeof getSlotsDateRanges>;
  pages: Page[];
  currentService?: ServiceDetails;
}) => {
  // Сопоставляем clinic branches по их id для быстрого поиска
  const groupedBranches = new Map(
    pages
      .flatMap((page) => page.branches?.results)
      .filter(nonNullable)
      .map((branch) => [branch.id, branch]),
  );

  // Для каждой группы докторов обрабатываем доктора и
  // дополнительную информацию по карточкам
  return pages.map((page, pageIndex) => {
    const areDoctorsFetching = page.doctors?.isFetching;
    const areBranchesFetching = page.branches?.isFetching;
    const areServicesFetching = page.services?.isFetching;
    const areSlotsFetching = page.slots?.isFetching;

    const areServicesFetched =
      page.services?.isFetched !== undefined && page.services.isFetched;
    const areSlotsFetched =
      page.slots?.isFetched !== undefined && page.slots.isFetched;

    const areSlotsEmpty = areServicesFetched && areSlotsFetched;
    !dateRanges[pageIndex]?.length;

    // Группируем doctors services по id доктора,
    // и на втором уровне по clinic branch id для быстрого поиска
    const groupedServices = (page.services?.results || [])
      .filter(nonNullable)
      .reduce((acc, service) => {
        const branches =
          acc.get(service.doctor) || new Map<number, DoctorService[]>();
        const services = branches.get(service.clinic_branch) || [];
        services.push(service);
        branches.set(service.clinic_branch, services);
        acc.set(service.doctor, branches);

        return acc;
      }, new Map<number, Map<number, DoctorService[]>>());

    // Группируем initial слоты по id доктора
    // и на втором уровне по clinic branch id для быстрого поиска
    const groupedSlots = (page.slots?.results || [])
      .filter(nonNullable)
      .reduce((acc, slot) => {
        const branches = acc.get(slot.doctor) || new Map<number, Slot[]>();
        const slots = branches.get(slot.clinic_branch) || [];
        slots.push(slot);
        branches.set(slot.clinic_branch, slots);
        acc.set(slot.doctor, branches);
        return acc;
      }, new Map<number, Map<number, Slot[]>>());

    // Собираем информацию по карточкам в группе
    const cards = page.doctors?.results.map((doctor) => {
      const doctorServices = groupedServices.get(doctor.id);
      const branchSlots = groupedSlots.get(doctor.id);

      return {
        doctor,
        branches_services: doctor.clinic_branches
          ?.filter((branchId) => groupedBranches.has(branchId))
          .map((branchId) => {
            const branch = groupedBranches.get(branchId)!;
            const branchServices = doctorServices?.get(branchId) || [];

            const services = (targetServices || [])
              .map((target) => {
                const branchService = branchServices.find(
                  (service) => service.service === target.id,
                );
                if (!branchService) return null;

                return {
                  ...branchService,
                  service_name: target.name,
                  service_type: target.type,
                };
              })
              .filter(truthy);

            if (services.length === 0) return null;

            return {
              branch,
              services,
            };
          })
          .filter(truthy),
        initialSlots: branchSlots || new Map(),
        branches_specialties:
          currentService?.type === "procedure"
            ? doctor.clinic_branches
                ?.filter((branchId) => groupedBranches.has(branchId))
                .map((branchId) => {
                  const branch = groupedBranches.get(branchId)!;
                  const branchServices = doctorServices?.get(branchId) || [];
                  const targetServices = branchServices.filter(
                    (branch) =>
                      branch.service_type === "initial-appointment" &&
                      (Boolean(currentService.adult_service)
                        ? branch.age_to !== null && branch.age_from !== null
                        : branch),
                  );

                  const services = targetServices
                    .map((target) => {
                      const branchService = branchServices.find(
                        (service) => service.service === target.service,
                      );
                      if (!branchService) return null;

                      return branchService;
                    })
                    .filter(truthy);

                  if (services.length === 0) return null;

                  return {
                    branch,
                    services,
                  };
                })
                .filter(truthy)
            : [],
      };
    });

    return {
      seen: page.seen,
      size: page.size,
      isFetching:
        areDoctorsFetching ||
        areBranchesFetching ||
        areServicesFetching ||
        areSlotsFetching,
      areDoctorsFetching,
      areServicesFetching,
      areSlotsFetching,
      areBranchesFetching,
      areSlotsEmpty,
      cards,
    };
  });
};
