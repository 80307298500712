import { css } from "@emotion/react";
import type { Doctor, DoctorService } from "@hermes/api";
import { Message, useFormatNumber } from "@hermes/intl";
import { cssFns, usePrincipalColors, useScreenDetector } from "@hermes/ui";
import { Link } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";

import { DoctorProfileImage } from "./profile-image";

export const DoctorProfileReview = ({
  doctor,
  disableReview = false,
  doctorService,
  imageSize,
  isClickable = true,
  displayAllReviews,
}: {
  doctor?: Doctor;
  doctorService?: DoctorService;
  disableReview?: boolean;
  imageSize?: string;
  isClickable?: boolean;
  displayAllReviews?: boolean;
}) => {
  const { routePath } = useRoutePath();
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const formatNumber = useFormatNumber();

  const feedbackCount =
    (displayAllReviews
      ? doctor?.feedback_count
      : doctorService?.feedback_count) ?? 0;

  const feedbackScore =
    (displayAllReviews
      ? doctor?.feedback_score
      : doctorService?.feedback_score) ?? 0;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <DoctorProfileImage
        doctor={doctor}
        size={(imageSize ?? isMobile) ? "80px" : "110px"}
        data-selector={disableReview ? "doctorProfilePhoto" : ""}
        doctor_service_id={doctorService?.id}
        styles={css({
          marginBlockEnd: !disableReview
            ? isMobile
              ? "8px"
              : "12px"
            : undefined,
        })}
        isClickable={isClickable}
      />
      {!disableReview && doctor && (
        <div
          css={[
            cssFns.typo({ weight: "regular", level: "body-1" }),
            { color: principalColors.gs2 },
          ]}
        >
          {formatNumber(feedbackScore, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
          })}
        </div>
      )}
      {!disableReview && doctor && (
        <Link
          to={routePath({
            path: "/doctor/:slug",
            params: {
              slug: doctor.slug,
            },
            hash: "reviews",
            query: {
              doctor_service_id: doctorService?.id.toString(),
              with_all_reviews: displayAllReviews || undefined,
            },
          })}
          css={[
            isMobile
              ? cssFns.typo({ weight: "regular", level: "body-3" })
              : cssFns.typo({ weight: "regular", level: "body-2" }),
            {
              color: principalColors.ebblue,
              textDecoration: "none",
            },
          ]}
        >
          <Message
            id="common.count.feedbacks"
            default="{count} {count, plural, one {отзыв} few {отзыва} other {отзывов}}"
            values={{
              count: feedbackCount,
            }}
          />
        </Link>
      )}
    </div>
  );
};
