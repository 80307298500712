import {
  type AppointmentField,
  type DoctorService,
  type Insurance,
  type Slot,
} from "@hermes/api";
import { Flag } from "@hermes/flags";
import { Temporal, useFormatDateTime, useMessage } from "@hermes/intl";
import { type TimeSlot } from "@hermes/shared";
import {
  ButtonField,
  type ButtonFieldState,
  type CheckboxState,
  type Field,
  type FieldState,
  type FormFields,
  PhoneField,
  Select,
  type SelectState,
  TextField,
  type TextFieldState,
  truthy,
  useScreenDetector,
} from "@hermes/ui";

import { useAppConfig } from "#internal/app/config/context";

import { AdditionalField } from "./additional-fields";
import { isServiceHasChild } from "./child";
import { AppointmentConsentDataBlock } from "./consent-data-field";
import { InsuranceField } from "./insurance-field";

export const AppointmentForm = ({
  fields,
  appointment_fields,
  additionalFields,
  doctor_services,
  selectedService,
  onChangeSlotPress,
  enableConsent,
  insurances,
  insuranceFields,
}: {
  fields: FormFields<{
    service: Field<SelectState<number>>;
    slot: Field<ButtonFieldState<TimeSlot>>;
    name: Field<TextFieldState>;
    surname: Field<TextFieldState>;
    phone: Field<TextFieldState>;
    consent: Field<FieldState<any>>;
  }>;
  appointment_fields: AppointmentField[];
  insuranceFields: FormFields<{
    select: Field<SelectState<Insurance>>;
    checkbox: Field<CheckboxState>;
  }>;
  additionalFields: FormFields<{
    [x: string]: Field<FieldState<any>>;
  }>;
  doctor_services: DoctorService[];
  selectedService?: DoctorService;
  branchSlots?: Slot[];
  onSubmit?: () => void;
  onChangeSlotPress?: () => void;
  enableConsent: boolean;
  insurances: Insurance[];
}) => {
  const { isMobile } = useScreenDetector();
  const {
    country: { phone },
  } = useAppConfig();
  const message = useMessage();
  const formatDateTime = useFormatDateTime();

  const formatSlot = (value: Temporal.PlainDateTime) =>
    formatDateTime(value, {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });

  const specialties = doctor_services
    .filter((service) => service.service_type === "initial-appointment")
    .map((service) => ({
      label: service.service_name || "",
      value: service.service,
    }));
  const procedures = doctor_services
    .filter((service) => service.service_type === "procedure")
    .map((service) => ({
      label: service.service_name || "",
      value: service.service,
    }));

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
      }}
      onFocusCapture={(e) => {
        if (isMobile && e.target.nodeName === "INPUT") {
          e.target.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }}
    >
      {insurances.length > 0 && (
        <InsuranceField
          fields={insuranceFields}
          options={insurances.map((insurance) => ({
            label: insurance.name,
            value: insurance,
          }))}
        />
      )}
      <Select
        field={fields.service}
        label={message({
          id: "common.services.specialties-procedures",
          default: "Специальность/процедура",
        })}
        variant="desktop"
        description={
          isServiceHasChild(doctor_services, selectedService)
            ? message({
                id: "264b0ae8-2aba-460d-ac5a-686e0d29591a",
                default:
                  "Если записываете ребенка, то выберите детскую специальность врача",
              })
            : undefined
        }
        options={[
          specialties.length && {
            label: message({
              id: "common.services.initial-appointment",
            }),
            options: specialties,
          },
          procedures.length && {
            label: message({
              id: "common.services.procedures",
            }),
            options: procedures,
          },
        ].filter(truthy)}
        onSelectionChange={() => {
          fields.slot.onChange({
            ...fields.slot,
            value: null,
          });
        }}
        disableLabelFocus
      />
      <ButtonField
        field={fields.slot}
        valueToString={(value) => (value ? formatSlot(value.time) : "")}
        label={message({
          id: "414f9cba-1fd0-4f0c-bd58-94e725c7c6ab",
          default: "Дата и время приема",
        })}
        placeholder={message({
          id: "2d7a24b7-0d38-4093-9c8b-66d7d176352a",
          default: "Выберите время",
        })}
        onPress={onChangeSlotPress}
        disableLabelFocus
      />
      <TextField
        field={fields.name}
        label={message({
          id: "common.form.client-name",
          default: "Имя пациента",
        })}
        autoComplete="name"
        name="name"
        disableLabelFocus
      />
      <Flag id="appointment-modal.enable-surname">
        <TextField
          field={fields.surname}
          label={message({
            id: "common.form.client-surname",
            default: "Фамилия пациента",
          })}
          autoComplete="name"
          name="surname"
          disableLabelFocus
        />
      </Flag>
      {appointment_fields.map((fieldInfo) => {
        const field = additionalFields[fieldInfo.id];
        if (!field) return null;

        return (
          <AdditionalField key={fieldInfo.id} field={field} info={fieldInfo} />
        );
      })}
      <PhoneField
        pattern={phone.mask}
        field={fields.phone}
        label={message({
          id: "common.form.client-phone",
          default: "Телефон пациента",
        })}
        description={message({
          id: "2bc8885e-1f6e-422f-ba43-e18275af7740",
          default:
            "На указанный вами номер будет отправлено SMS с кодом подтверждения",
        })}
        name="phone"
        disableLabelFocus
      />
      {enableConsent && <AppointmentConsentDataBlock field={fields.consent} />}
    </div>
  );
};
