/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ButtonV2 } from "@hermes/ui";
import { useState } from "react";

import { DropdownBody } from "./dropdown-body";

export type DropdownItem = {
  label: string;
  icon?: JSX.Element;
  onClick: () => void;
};

export type DropdownButtonProps = {
  label: string;
  icon?: JSX.Element;
  items: DropdownItem[];
  className?: string;
};

export const DropdownMenu = ({
  label,
  icon,
  items,
  className,
}: DropdownButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownBody isOpen={isOpen} onToggle={setIsOpen} className={className}>
      {({ open, toggleDropdown }) => (
        <>
          <ButtonV2
            text={label}
            icon={icon}
            onPress={toggleDropdown}
            variant="solid"
          />
          {open && (
            <div css={dropdownMenuStyles}>
              {items.map(({ label, icon, onClick }, index) => (
                <button key={index} css={dropdownItemStyles} onClick={onClick}>
                  {icon && <span css={iconStyles}>{icon}</span>}
                  {label}
                </button>
              ))}
            </div>
          )}
        </>
      )}
    </DropdownBody>
  );
};

const dropdownMenuStyles = css`
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  border-radius: 4px;
  min-width: 160px;
  z-index: 1000;
`;

const dropdownItemStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  padding: 8px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

const iconStyles = css`
  margin-right: 8px;
  display: flex;
  align-items: center;
`;
