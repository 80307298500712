import { type DoctorService } from "@hermes/api";

export const isChildService = ({ age_to }: DoctorService) =>
  age_to && age_to <= 216;

export const isServiceHasChild = (
  doctor_services: DoctorService[],
  service?: DoctorService,
) => {
  return (
    service &&
    (service.age_to === null || (service.age_to && service.age_to > 216)) &&
    doctor_services.filter((s) => s.age_to && s.age_to <= 216)[0]
  );
};

export const isAllServiceHasChild = (service?: DoctorService) => {
  return !!(service && service.age_to && service.age_to <= 216);
};
