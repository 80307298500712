import type { CSSObject, SerializedStyles } from "@emotion/react";
import { useRef } from "react";
import { useRadio, useRadioGroup, VisuallyHidden } from "react-aria";
import {
  type RadioGroupState as AriaRadioGroupState,
  useRadioGroupState as useAriaRadioGroupState,
} from "react-stately";

import { cssFns } from "../css";
import {
  CircleChecked,
  CircleCheckedBlue,
  CircleDefault,
  CircleDefaultLg,
  DesktopPopupDone,
} from "../icons";
import { useScreenDetector } from "../screen-type";
import { usePrincipalColors } from "../theme";
import type { SizeUnit } from "../utils";
import { FieldDescription } from "./common/description";
import { FieldError } from "./common/error";
import { BaseFieldLayout } from "./common/field";
import { type Field, type FieldState, useBaseFieldState } from "./common/form";
import { FieldLabel } from "./common/label";
import type { CommonFieldProps } from "./common/props";

export type RadioGroupState<T extends string> = FieldState<T | null>;

export type RadioGroupOption<T extends string> = {
  value: T;
  label: string;
};

function RadioButton<T extends string>({
  state,
  option,
  position,
  variant,
  width,
}: {
  state: AriaRadioGroupState;
  option: RadioGroupOption<T>;
  position?: "middle" | "end";
  variant?: "cancel-reason" | "feedback-rate" | "inline";
  width?: SizeUnit;
}) {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected } = useRadio(
    {
      value: option.value,
      "aria-label": option.label,
    },
    state,
    ref,
  );

  const styles: Array<CSSObject | SerializedStyles> = [];

  switch (variant) {
    case "cancel-reason":
      styles.push(
        {
          color: isSelected ? principalColors.gs4 : principalColors.gs2,
          display: "flex",
          justifyContent: isMobile ? "space-between" : "",
          alignItems: "center",
          margin: isMobile ? "0 10px" : "0 16px",
          cursor: "pointer",
          columnGap: isMobile ? 6 : 20,
        },
        cssFns.padding(isMobile ? 20 : 16, 0),
        cssFns.typo({ level: "body-1", weight: "regular" }),
      );
      if (position !== "end") {
        styles.push(
          cssFns.borderBlockEnd({
            color: principalColors.grayline,
            width: "1px",
            style: "solid",
          }),
        );
      }
      break;
    case "feedback-rate":
      styles.push(
        isMobile
          ? cssFns.typo({ level: "body-1", weight: "semibold" })
          : cssFns.typo({ level: "body-2", weight: "semibold" }),
        isMobile ? cssFns.padding("9px") : cssFns.padding("8px"),
        cssFns.border({
          radius: "0",
          width: "0px",
        }),
        {
          background: isSelected
            ? principalColors.ebblue
            : cssFns.setOpacity(principalColors.ebblue, 0.14),

          color: isSelected ? principalColors.white : principalColors.ebblue,
          height: isMobile ? 35 : 40,
          width: width ? width : isMobile ? "100%" : 40,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingBlockStart: isMobile ? undefined : 11,
        },
      );
      break;
    case "inline":
      styles.push(
        {
          color: isSelected ? principalColors.ebblue : principalColors.gs2,
          display: "flex",
          alignItems: "center",
          margin: 0,
          cursor: "pointer",
          columnGap: 10,
          border: "none",
        },
        cssFns.typo({ level: "body-1", weight: "regular" }),
      );
      break;
  }

  return (
    <label css={styles}>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      {variant === "cancel-reason" &&
        !isMobile &&
        (isSelected ? <CircleChecked /> : <CircleDefault />)}
      {variant === "inline" &&
        (isSelected ? <CircleCheckedBlue /> : <CircleDefaultLg />)}
      {option.label}
      {variant === "cancel-reason" && isSelected && isMobile && (
        <DesktopPopupDone color={principalColors.ebblue} />
      )}
    </label>
  );
}

export function RadioGroup<T extends string>({
  label,
  description,
  field,
  onSelectionChange,
  options,
  variant = "cancel-reason",
  width,
  disableLabelFocus,
}: {
  field: Field<RadioGroupState<T>>;
  onSelectionChange?: (option: RadioGroupOption<T> | null) => void;
  options: RadioGroupOption<T>[];
  variant: "cancel-reason" | "feedback-rate" | "inline";
  width?: SizeUnit;
} & CommonFieldProps) {
  const fieldRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenDetector();

  const state = useAriaRadioGroupState({
    value: field.value,
    onChange: (value) => {
      field.onChange({
        ...field,
        value: value as T,
      });
      onSelectionChange?.(
        options.find((option) => option.value === value) ?? null,
      );
    },
  });
  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
    useRadioGroup(
      {
        "aria-label": label ?? "radio-group",
      },
      state,
    );

  const styles: Array<CSSObject | SerializedStyles> = [];

  switch (variant) {
    case "cancel-reason":
      styles.push({
        display: "flex",
        flexDirection: "column",
      });
      break;
    case "feedback-rate":
      styles.push(
        {
          display: "flex",
          overflow: "hidden",
          gap: 1,
          width: isMobile ? "100%" : "fit-content",
          marginBlockStart: 12,
        },
        cssFns.border({ radius: "4px", width: "0px" }),
      );
      break;
    case "inline":
      styles.push({
        display: "flex",
        gap: 24,
      });
      break;
  }

  return (
    <BaseFieldLayout
      fieldRef={fieldRef}
      label={
        label && (
          <FieldLabel
            labelProps={labelProps}
            disabled={field.disabled}
            required={field.required}
            disableFocus={disableLabelFocus}
          >
            {label}
          </FieldLabel>
        )
      }
      input={
        <div {...radioGroupProps} css={styles}>
          {options.map((option, index) => (
            <RadioButton
              key={option.value}
              option={option}
              state={state}
              position={index === options.length - 1 ? "end" : "middle"}
              variant={variant}
              width={width}
            />
          ))}
        </div>
      }
      description={
        description && (
          <FieldDescription
            disabled={field.disabled}
            descriptionProps={descriptionProps}
          >
            {description}
          </FieldDescription>
        )
      }
      error={
        field.error?.visible && (
          <FieldError
            errorMessageProps={errorMessageProps}
            errorMessage={field.error.message}
            fieldRequired={field.required}
          />
        )
      }
    />
  );
}

export function useRadioGroupState<T extends string>(
  defaultState?: RadioGroupState<T>,
) {
  return useBaseFieldState<RadioGroupState<T>>({
    value: null,
    error: undefined,
    ...defaultState,
  });
}
